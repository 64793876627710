import React from 'react';
import './style.css';

const Tooltip = ({children, text, disabled}) => {
  const truncatedText = text.length > 80 ? text.substring(0, 80) + '...' : text;

  return (
    <span className="tooltip-container">
      <div className="tooltip-trigger" tabIndex={0}>
        {children}
      </div>
      {!disabled && (
        <span className="tooltip-content" role="tooltip" aria-hidden="true">
          {truncatedText}
        </span>
      )}
    </span>
  );
};

export const InvertedTooltip = ({children, text, disabled}) => {
  const truncatedText = text.length > 80 ? text.substring(0, 80) + '...' : text;

  return (
    <span className="tooltip-container inverted-tooltip">
      <div className="tooltip-trigger" tabIndex={0}>
        {children}
      </div>
      {!disabled && (
        <span className="tooltip-content" role="tooltip" aria-hidden="true">
          {truncatedText}
        </span>
      )}
    </span>
  );
};

export const TooltipNoShadow = ({children, text}) => {
  const truncatedText = text.length > 80 ? text.substring(0, 80) + '...' : text;

  return (
    <span className="tooltip-container">
      <div className="tooltip-trigger-no-shadow" tabIndex={0}>
        {children}
      </div>
      <span className="tooltip-content" role="tooltip" aria-hidden="true">
        {truncatedText}
      </span>
    </span>
  );
};

export const TooltipRight = ({children, text, subtext, disabled}) => {
  const truncatedText = text.length > 80 ? text.substring(0, 80) + '...' : text;

  return (
    <span className="tooltip-container tooltip-right">
      <div className="tooltip-trigger-no-shadow" tabIndex={0}>
        {children}
      </div>
      {!disabled && (
        <span
          className="tooltip-content-right"
          role="tooltip"
          aria-hidden="true">
          {truncatedText}
          {subtext && <span className="cmd-style">{subtext}</span>}
        </span>
      )}
    </span>
  );
};

export default Tooltip;
