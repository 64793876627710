/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const extractEmail = inputString => {
    if (!inputString) {
      return null;
    }
    // Regex pattern to find the email inside angle brackets
    const regex = /<([^>]+)>/;
    const match = inputString.match(regex);

    if (match) {
      // Return the email address if found
      return match[1];
    } else {
      // Return null or an appropriate message if no email is found
      return inputString;
    }
  };

  const isPersonalEmail = emailAddress => {
    // List of patterns found in automated emails
    const automatedPatterns = [
      /noreply@/,
      /info@/,
      /support@/,
      /admin@/,
      /customer\.service@/,
      /newsletter@/,
      /service@/,
      /no-reply@/,
      /mail@/,
    ];

    // Check if the email address matches any of the automated patterns
    for (let pattern of automatedPatterns) {
      if (pattern.test(emailAddress)) {
        return false; // It's likely an automated email
      }
    }

    // Additional checks can be added here based on other criteria

    return true; // Likely a personal email
  };

  const categorizeEmails = (emails, current) => {
    if (!emails) {
      return;
    }
    const categorized = {};
    emails?.forEach(email => {
      if (!email) {
        return;
      }
      const {
        payload: {headers},
      } = email || {};
      const to = headers.find(header => header.name === 'To');
      const from = headers.find(header => header.name === 'From');
      const to_email = extractEmail(to?.value);
      const from_email = extractEmail(from?.value);

      if (!to_email?.includes(current)) {
        if (categorized[to_email] === undefined) {
          categorized[to_email] = 0;
        }
        categorized[to_email] += 1;
      }
      if (!from_email?.includes(current)) {
        if (categorized[from_email] === undefined) {
          categorized[from_email] = 0;
        }
        categorized[from_email] += 1;
      }
    });
    const sorted = Object.keys(categorized)
      .map(key => {
        const value = categorized[key];
        return {key, value, active: false};
      })
      .filter(email => isPersonalEmail(email.key))
      .sort((a, b) => {
        return b.value - a.value;
      });
    return sorted;
  };

  return {extractEmail, isPersonalEmail, categorizeEmails};
};
