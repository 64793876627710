/* eslint-disable import/no-anonymous-default-export */
import {GoogleOAuthProvider} from '@react-oauth/google';
import {ErrorBoundary} from '@sentry/react';
import {Amplify} from 'aws-amplify';
import {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {IntercomProvider, useIntercom} from 'react-use-intercom';
import {identify, initAnalytics} from './api/analytics';
import awsExports from './aws-exports';
import AuthGuard from './components/layout/auth-guard';
import {
  AuthLayout,
  FeedLayout,
  MobileLayout,
  PublicLayout,
} from './components/layout/layout';
import {Provider as AuthProvider} from './contexts/auth-context';
import {Provider as MessengerProvider} from './contexts/message-context';
import {Provider as NetworkingProvider} from './contexts/networking-context';
import {Provider as WorkspaceProvider} from './contexts/workspace-context';
import {useAuth} from './hooks/use-auth';
import useTracker from './hooks/use-tracker';
import ConfirmAccount from './pages/auth/confirm-account';
import ConfirmSubscription from './pages/auth/confirm-subscription';
import CreateOrganization from './pages/auth/create-organization';
import CreateProfile from './pages/auth/create-profile';
import FindOrganization from './pages/auth/find-organization';
import ForgotPassword from './pages/auth/forgot-password';
import PaymentPending from './pages/auth/payment-pending';
import Pending from './pages/auth/pending';
import ProfileTagRequests from './pages/auth/profile-tag-request';
import ProfileTagResources from './pages/auth/profile-tag-resources';
import Root from './pages/auth/root';
import Signin from './pages/auth/signin';
import Signup from './pages/auth/signup';
import WelcomeAccount from './pages/auth/welcome-account';
import OrganizationDetail from './pages/data/organization-detail';
import ProfileDetail from './pages/data/profile-detail';
import Search from './pages/data/search';
import DesktopOnlyPage from './pages/desktop-only/desktop-only';
import ComingSoon from './pages/error/coming-soon';
import OrganizationError from './pages/error/organization-error';
import PageNotFound from './pages/error/page-not-found';
import CreateGroup from './pages/groups/create-group';
import GroupDetail from './pages/groups/group-detail';
import GroupsOverview from './pages/groups/groups-overview';
import Home from './pages/home/home';
import PrivacyPolicy from './pages/legal/privacy-policy';
import TermsOfService from './pages/legal/terms-of-service';
import MessagesOverview from './pages/messages/messages-overview';
import ConnectEmail from './pages/notes/connect-email';
import {PersonNoteItem} from './pages/notes/note';
import NotesHomepage from './pages/notes/notes-home';
import OpportunitiesList from './pages/opportunities/opportunities-list';
import OpportunitiesOverview from './pages/opportunities/opportunities-overview';
import OpportunityDetail from './pages/opportunities/opportunity-detail';
import PostDetail from './pages/posts/post-detail';
import SharePost from './pages/posts/share-post';
import Triggers from './pages/public/triggers';
import Canny from './pages/settings/canny';
import EmailPreview from './pages/settings/email-preview';
import MasterSettings from './pages/settings/master-settings';
import OrganizationSettings from './pages/settings/organization-settings';
import ProfileSettings from './pages/settings/profile-settings';

Amplify.configure(awsExports);

const App = () => {
  const {boot, shutdown, hide, show, update} = useIntercom();
  const {
    state: {profile, auth},
    init,
    listener,
  } = useAuth();

  const [listening, setListening] = useState(false);
  const mobile = isMobile && !isTablet;

  useEffect(() => {
    init();
    if (!listening) {
      listener();
      setListening(true);
    }
    // IF NOT DEVELOPMENT
    if (process.env.NODE_ENV === 'production') {
      initAnalytics();
    }
  }, []);

  // UPDATE MIXPANEL/INTERCOM WITH NEW PROPS
  useEffect(() => {
    if (profile) {
      const {
        id,
        email,
        first_name,
        last_name,
        phone,
        position,
        bio,
        organization_ids,
        status,
        createdAt = '',
        inner_tags,
        outer_tags,
      } = profile;

      // MIXPANEL
      identify(id, {
        $email: email,
        $first_name: first_name,
        $last_name: last_name,
        $created: createdAt,
        aws_sub: id,
        organization_ids,
        phone,
        position,
        bio,
        status,
      });

      // INTERCOM
      update({
        email,
        userId: id,
        name: `${first_name} ${last_name}`,
        createdAt,
        customAttributes: {
          job_title: position,
          inner_tags,
          outer_tags,
          status,
        },
      });
    } else {
      // INTERCOM
      update({email: null, userId: null, name: null, demo: true});
    }
  }, [profile]);

  if (mobile) {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* <Route
            path="/public/profile/:id"
            element={
              <div className="margin-v32">
                <PublicProfileDetail />
              </div>
            }
          /> */}
          <Route path="/public/profile/:id" element={<SignupRedirect />} />
          <Route
            path="/feed/posts/detail/:id"
            element={
              <div className="flex justify-center margin-v32">
                <PostDetail />
              </div>
            }
          />

          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Root />} />
            <Route path={'*'} element={<DesktopOnlyPage />} />
            <Route path={'/desktop-only'} element={<DesktopOnlyPage />} />
            <Route path="signin" element={<Signin />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="signup" element={<Signup />} />
            <Route path="confirm" element={<ConfirmAccount />} />
            <Route path="/" element={<AuthGuard reroute={false} />}>
              <Route path="create-profile" element={<CreateProfile />} />
              <Route path="introduce-yourself" element={<WelcomeAccount />} />
              <Route
                path="profile-tag-resources"
                element={<ProfileTagResources />}
              />
              <Route
                path="profile-tag-requests"
                element={<ProfileTagRequests />}
              />
              <Route path="find-organization" element={<FindOrganization />} />
              <Route
                path="create-organization"
                element={<CreateOrganization />}
              />
              <Route
                path="confirm-subscription"
                element={<ConfirmSubscription />}
              />
              <Route path={'/pending'} element={<Pending />} />
              <Route path={'/payment-pending'} element={<PaymentPending />} />
            </Route>
          </Route>
          <Route
            path="/"
            element={
              <AuthGuard reroute={true}>
                <MobileLayout />
              </AuthGuard>
            }>
            <Route path={'/home'} element={<Home />} />
            <Route
              path={'/opportunities/detail/:id'}
              element={<OpportunityDetail />}
            />
            <Route path="/posts/share/:id" element={<SharePost />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      {/* SCROLLS TO TOP WHEN NAVIGATE TO NEW PAGE */}
      <ScrollToTop />
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* PUBLIC */}
        <Route path="/" element={<AuthGuard reroute={false} force={true} />}>
          {/* <Route
            path="/public/profile/:id"
            element={
              <div className="flex justify-center margin-v32">
                <div className="" style={{width: '60vw'}}>
                  <PublicProfileDetail />
                </div>
              </div>
            }
          /> */}
          <Route path="/public/profile/:id" element={<SignupRedirect />} />
        </Route>
        <Route path="/" element={<PublicLayout />}>
          <Route path="trigger" element={<Triggers />} />
        </Route>
        {/* AUTH LAYOUT */}
        {process.env.NODE_ENV === 'development' && (
          <Route path="/email-preview" element={<EmailPreview />} />
        )}
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Root />} />
          <Route path="signin" element={<Signin />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="signup" element={<Signup />} />
          <Route path="confirm" element={<ConfirmAccount />} />
          <Route path="/" element={<AuthGuard reroute={false} />}>
            <Route path="create-profile" element={<CreateProfile />} />
            <Route path="introduce-yourself" element={<WelcomeAccount />} />
            <Route path={'/pending'} element={<Pending />} />
            <Route path={'/payment-pending'} element={<PaymentPending />} />
          </Route>
        </Route>
        {/* DASHBOARD LAYOUT AND AUTH GUARDED */}
        <Route
          path="/"
          element={
            <AuthGuard reroute={true}>
              <FeedLayout />
            </AuthGuard>
          }>
          {/* ERROR HANDLING */}
          <Route path={'*'} element={<PageNotFound />} />
          <Route path={'/organization-error'} element={<OrganizationError />} />
          <Route path={'/coming-soon'} element={<ComingSoon />} />
          {/* HOME */}
          <Route path={'/home'} element={<Home />} />
          {/* OPPORTUNITIES */}
          <Route path={'/opportunities'} element={<OpportunitiesOverview />} />
          <Route path={'/opportunities/list'} element={<OpportunitiesList />} />
          <Route
            path={'/opportunities/detail/:id'}
            element={<OpportunityDetail />}
          />
          {/* NOTES */}
          <Route path={'/notes/connect'} element={<ConnectEmail />} />
          <Route path={'/notes'} element={<NotesHomepage />} />
          <Route path="/notes/:personId" element={<PersonNoteItem />} />
          {/* GROUPS */}
          <Route path={'/groups'} element={<GroupsOverview />} />
          <Route path={'/groups/detail/:id'} element={<GroupDetail />} />
          <Route path={'/groups/create/:id'} element={<CreateGroup />} />
          {/* SEARCH */}
          <Route path={'/feed'} element={<Search />} />
          <Route path={'/feed/:query'} element={<Search />} />

          <Route
            path="/feed/organizations/detail/:id"
            element={<OrganizationDetail />}
          />
          <Route
            path="/feed/posts/detail/:id"
            element={
              auth ? (
                <div className="flex justify-center">
                  <div className="padding-left64">
                    <PostDetail />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center margin-v32">
                  <div className="padding-left64">
                    <PostDetail />
                  </div>
                </div>
              )
            }
          />
          <Route path="/feed/profiles/detail/:id" element={<ProfileDetail />} />
          <Route path="/posts/share/:id" element={<SharePost />} />
          <Route path="/messages" element={<MessagesOverview />} />
          {/* SETTINGS */}
          <Route path="/settings/profile" element={<ProfileSettings />} />
          <Route
            path="/settings/organization"
            element={<OrganizationSettings />}
          />
          <Route path="/settings/feedback" element={<Canny />} />
          {process.env.NODE_ENV === 'development' && (
            <Route path="/settings/master" element={<MasterSettings />} />
          )}
          {/* ONBOARDING */}
          <Route path="find-organization" element={<FindOrganization />} />
          <Route path="create-organization" element={<CreateOrganization />} />
          <Route
            path="confirm-subscription"
            element={<ConfirmSubscription />}
          />
          <Route
            path="profile-tag-resources"
            element={<ProfileTagResources />}
          />
          <Route path="profile-tag-requests" element={<ProfileTagRequests />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const SignupRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/signup');
  }, []);
  return null;
};

const ScrollToTop = () => {
  const {trackPage} = useTracker();
  const {pathname} = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the location (pathname) changes
    window.scrollTo(0, 0);
    // IGNORE CERTAIN SCREENS, OTHERWISE TRACK ALL PAGE VIEWS
    switch (pathname) {
      case 'rwa-career-fairs':
      case 'rwa-luncheon':
      case 'rwa-summit':
        return;
      default:
        if (pathname.includes('/feed/posts/detail/')) {
          return;
        } else {
          trackPage(pathname);
        }
    }
  }, [pathname]);

  return null;
};

function FallbackComponent() {
  return (
    <div className="flex-column justify-center align-center">
      <br />
      <br />
      <p>An error has occurred. Try refreshing the page.</p>
    </div>
  );
}

export default () => {
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM;
  // PUBLICMIND
  const client_id =
    '574564987272-kr743dsu1n2kblbc3gihqjv91emj84rk.apps.googleusercontent.com';
  // FINSITEFUL
  const client_id2 =
    '233610274066-0c3giohk8fu9h98lvr9c4o8lt1e756r1.apps.googleusercontent.com';

  return (
    <ErrorBoundary fallback={FallbackComponent} showDialog>
      <GoogleOAuthProvider clientId={client_id}>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
          <NetworkingProvider>
            <WorkspaceProvider>
              <MessengerProvider>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </MessengerProvider>
            </WorkspaceProvider>
          </NetworkingProvider>
        </IntercomProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
};
