import axios from 'axios';
import {track} from './analytics';

const paramsToString = params => {
  try {
    // Initialize an array to hold the key-value pairs
    let queryStringComponents = [];

    // Iterate over each key in the params object
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        // Get the value associated with the key
        let value = params[key];
        if (!value) {
          break;
        }
        // Convert non-string values to strings
        if (typeof value !== 'string') {
          value = String(value);
        }
        // Encode both the key and the value for URL safety
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(value);
        // Push the encoded key-value pair to the array
        queryStringComponents.push(`${encodedKey}=${encodedValue}`);
      }
    }

    if (queryStringComponents.length) {
      // Join all components with '&' to form the final query string
      return `?${queryStringComponents.join('&')}`;
    }
    return '';
  } catch (err) {
    console.log(err);
    return '';
  }
};

const fetchGoogleProfile = async token => {
  try {
    const {data} = await axios.get(
      'https://www.googleapis.com/oauth2/v1/userinfo',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (err) {
    track('api_error', {type: 'google', function: 'fetchProfile', err});
    console.log(err);
  }
};

const listEmails = async (token, options) => {
  try {
    const query_string = paramsToString(options);
    const endpoint = `https://gmail.googleapis.com/gmail/v1/users/me/messages${query_string}`;
    const {data} = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        options,
      },
    });
    return data;
  } catch (err) {
    track('api_error', {type: 'google', function: 'listEmails', err});
    throw err;
  }
};

const getEmailMetadata = async (token, id) => {
  try {
    const headersToRequest = ['From', 'To', 'Subject', 'Date']; // Add or remove headers as needed
    const headersQuery = headersToRequest
      .map(header => `metadataHeaders=${header}`)
      .join('&');
    const endpoint = `https://gmail.googleapis.com/gmail/v1/users/me/messages/${id}?format=metadata&${headersQuery}`;
    const {data} = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (err) {
    track('api_error', {type: 'google', function: 'fetchEmailMetadata', err});
    throw err;
  }
};

export {fetchGoogleProfile, getEmailMetadata, listEmails};
