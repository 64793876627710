import React, {useCallback, useState} from 'react';
import {BadgeDot} from '../badge/badge';
import './styles.css';

export const Tab = ({items, setIndex, index, style}) => {
  const [isThrottled, setIsThrottled] = useState(false);

  const throttledSetIndex = useCallback(
    i => {
      if (!isThrottled) {
        setIndex(i);
        setIsThrottled(true);
        setTimeout(() => {
          setIsThrottled(false);
        }, 300);
      }
    },
    [isThrottled, setIndex],
  );

  return (
    <div className="tab-container" style={style}>
      {items.map(item => {
        const {tab, index: i, notifications} = item;
        return (
          <div className="position-relative">
            <BadgeDot
              active={notifications}
              style={{top: '12px', right: '6px'}}
            />
            <button
              key={i}
              className={`tab ${i === index ? 'active-tab' : ''}`}
              onClick={() => {
                throttledSetIndex(i);
              }}>
              {tab}
            </button>
          </div>
        );
      })}
    </div>
  );
};
