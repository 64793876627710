const constants = {
  default_query: 'Show me active organizations?',
  // TIME
  hours_12: 43200000,
  days_30: 2592000,
  years_1: 31536000,

  // REGEX
  password_regex: '^(?=.*[A-Z])(?=.*[a-z])(?=.*d)(?=.*[@#$%^&+=!])',
  remove_special_chars_regex: /[^a-zA-Z0-9\s]+/g,
  https_url: /(https?:\/\/[^\s]+)/g,
  webcal_url: /(webcal:\/\/[^\s]+)/g,
  email_regex: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
  general_url:
    /\b(?:https?:\/\/)?(?:www\.)?[\w-]+\.[\w-]+\b(?::\d+)?(?:[/?#][^\s"]*)?/g,
  all_links: /\bhttps?:\/\/[\w.-]+\.[a-zA-Z]{2,}(?:\/[\w-]+)*\/?/g,

  // URLS
  root_url: window.location.origin,
  signin_url: window.location.origin + '/signup',
  collaborate_url: window.location.origin + '/collaborate',
  public_profile: window.location.origin + '/public/profile',
  profile_url: window.location.origin + '/feed/profiles/detail',
  post_url: window.location.origin + '/feed/posts/detail',
  organization_settings_url: window.location.origin + '/settings/organization',
  customer_agreement: 'https://www.publicmind.ai/evaluation-agreement',
  linkedin_profile: 'https://www.linkedin.com/company/publicmind/',
  publicmind_website: 'https://www.publicmind.ai',
  finsiteful_website: 'https://finsiteful.com',
  copyright_policy: 'https://www.publicmind.ai/copyright-policy',
  privacy_policy:
    'https://publicmind.notion.site/Privacy-Policy-09c91b58691146dab8fa42ed4cfb4c5d?pvs=4',
  terms_of_service:
    'https://publicmind.notion.site/Terms-of-Service-cff20009bfa24c68bab57e04c2e78574?pvs=4',
  help_center: 'https://intercom.help/publicmind/en',
  jacks_calendly:
    'https://calendly.com/jack-publicmind/publicmind-membership-discussion',
  intercom_help_center:
    'https://intercom.help/publicmind/en/collections/5307122-get-to-know-publicmind',
  // IMAGE URLS
  publicmind_logo: 'https://publicmind.s3.amazonaws.com/favicon.ico',

  // EMAILS
  contact_email: 'help@publicmind.ai',

  // PAGINATION
  message_limit: 15,
  chain_limit: 15,
  org_search_limit: 12,
  opportunity_limit: 500,
  all_items: 1000,

  // COLORS
  lightColors: ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'],

  darkColors: ['#0075FF', '#35199F', '#3F1AD1', '#35199F', '#3F1AD1'],

  rainbowColors: [
    '#8884d8',
    '#83a6ed',
    '#8dd1e1',
    '#82ca9d',
    '#a4de6c',
    '#d0ed57',
    '#ffc658',
  ],

  // MIME TYPES
  image_mime_types: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/svg+xml',
  ],
  video_mime_types: [
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/mpeg',
    'video/quicktime',
  ],
  pdf_mime_type: ['application/pdf'],

  // FILE SIZES
  file_20mb: 20971520,

  default_opp_categories: {
    incoming: {
      internal: {
        count: 0,
        ids: [],
      },
      external: {
        count: 0,
        ids: [],
      },
    },
    pending: {
      internal: {
        count: 0,
        ids: [],
      },
      external: {
        count: 0,
        ids: [],
      },
    },
    active: {
      ignore: {
        count: 0,
        ids: [],
      },
      connect: {
        count: 0,
        ids: [],
      },
      later: {
        count: 0,
        ids: [],
      },
    },
  },
};

export default constants;
