import React, {useEffect, useState} from 'react';
import {RxFilePlus, RxFileText} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import {useNetworking} from '../../hooks/use-networking';
import useStringFormatter from '../../hooks/use-string-formatter';
import {ProfileImage} from '../inputs/uploader';
import {SimpleTagDisplay} from '../text/display-fields';
import Tooltip from '../tooltip/tooltip';
import {StatusIcon} from '../widgets/widgets';
import './styles.css';

export const DetailedProfileItem = ({item}) => {
  const navigate = useNavigate();
  const {loadOrgs} = useLoader();
  const {prettyName} = useStringFormatter();

  const {
    id,
    first_name,
    last_name,
    inner_tags,
    outer_tags,
    organization_ids,
    status,
    email,
    position,
    profile_image,
  } = item;

  const {
    state: {organizations},
  } = useAuth();

  useEffect(() => {
    loadOrgs(organization_ids);
  }, [organization_ids]);

  const pretty_name = prettyName(item);

  return (
    <div
      className="base-item-container"
      onClick={() => {
        navigate(`/feed/profiles/detail/${id}`);
      }}>
      <div className="flex-row justify-between align-start">
        <div className="flex-row  align-center">
          <ProfileImage
            data={profile_image}
            style={{height: '48px', width: '48px'}}
          />
          <div>
            <div className="flex-row align-center padding-left8">
              <p className="text-left text-bold">{pretty_name}</p>
              <StatusIcon status={status} />
            </div>
            <p className="text-left text-secondary text-14 padding-left8">
              {position}
            </p>
          </div>
        </div>
        <NoteButton item={item} />
      </div>
      <div className="flex-row-wrap">
        {organization_ids.map(id => {
          const {name} = organizations?.[id] ?? {};
          return (
            <p className="text-secondary" key={id}>
              {name}
            </p>
          );
        })}
      </div>
      <SimpleTagDisplay inner_tags={inner_tags} outer_tags={outer_tags} />
    </div>
  );
};

const NoteButton = ({item}) => {
  const navigate = useNavigate();

  const {
    state: {rolodex, rolodex_content},
    createEntry,
  } = useNetworking();
  const {
    state: {id: curr_user},
  } = useAuth();

  const [note, setNote] = useState(null);

  const {id, first_name, last_name, email, position} = item;

  useEffect(() => {
    const current = rolodex.find(rolo_id =>
      rolodex_content?.[rolo_id]?.emails?.includes(id),
    );
    setNote(current);
  }, [rolodex]);

  return (
    <div>
      {note ? (
        <Tooltip text={`View your notes on ${first_name}`}>
          <div
            className="flex-column align-center margin-h16 clickable"
            onClick={event => {
              event.stopPropagation();
              navigate(`/notes/${note}`);
            }}>
            <RxFileText size={'25px'} />
          </div>
          <p className="text-secondary text-10">Notes</p>
        </Tooltip>
      ) : (
        <Tooltip text={`Create a note for ${first_name}`}>
          <div
            className="flex-column align-center margin-h16 clickable"
            onClick={async event => {
              event.stopPropagation();
              const now = dateToTimestamp();

              const rolodex = {
                id: v4(),
                source_user: curr_user,
                updated: now,
                created: now,
                first_name: first_name || null,
                last_name: last_name || null,
                emails: email ? [email] : [],
                job_title: position || null,
                company: null,
                locations: [],
                inner_tags: [],
                outer_tags: [],
                social_links: [],
                notes: [],
              };

              await createEntry(rolodex);
              track('rolodex_entry_created', {user: curr_user, rolodex});
              navigate(`/notes/${rolodex.id}`);
            }}>
            <RxFilePlus size={'25px'} />
            <p className="text-secondary text-10">Notes</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const ProfileItem = ({item, disabled}) => {
  const navigate = useNavigate();
  const {
    state: {id: user_id, current_organization: org_id},
  } = useAuth();
  const {
    id,
    email,
    status,
    name,
    first_name,
    last_name,
    position,
    profile_image,
  } = item || {};

  const pretty_name = name || (first_name || '') + ' ' + (last_name || '');

  if (!item) {
    return null;
  }

  return (
    <div
      className="profile-container"
      onClick={() => {
        if (disabled) {
          return;
        }
        navigate(`/feed/profiles/detail/${id}`);
      }}>
      <div className="flex-column">
        <div className="flex-row align-center">
          <ProfileImage
            data={profile_image}
            style={{height: '50px', width: '50px'}}
          />
          <div>
            <p className="text-left text-bold padding-left8">{pretty_name}</p>
            <p className="text-left padding-left8">{position}</p>
          </div>
        </div>
        <span className="flex-row padding-bottom16">
          {/* <p className="text-secondary">Email: </p> */}
          {/* <a
            href={`mailto:${email}`}
            className="flex margin-h8"
            onClick={() => {
              track('email_clicked', {email, user_id, org_id});
            }}>
            {email}
          </a> */}
        </span>
      </div>
    </div>
  );
};
