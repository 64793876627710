import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {FaCheck} from 'react-icons/fa6';
import {RiCloseFill} from 'react-icons/ri';
import {RxEnter, RxLightningBolt} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {convertToDate, dateToTimestamp, formatMMDDYYYY} from '../../api/dates';
import {sendPostFromOppToEmail} from '../../api/email';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import useTracker from '../../hooks/use-tracker';
import {useWorkspace} from '../../hooks/use-workspace';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {PrimaryButton} from '../buttons/buttons';
import {ProfileImage} from '../inputs/uploader';
import {SpinningIndicator} from '../loading/loading-indicator';
import {ScheduleModal} from '../modal/schedule-modal';
import Tooltip from '../tooltip/tooltip';
import {Label} from './labels';
import {SimplePostItem} from './post-item';

//USED IN FIND PARTNER FLOWS
export const InteractiveOpportunityItem = ({opp_id}) => {
  const {sortOpportunity} = useCollaborator();
  const {loadProfiles, loadPosts} = useLoader();

  const {
    state: {id, profiles},
  } = useAuth();
  const {
    state: {posts, opportunities},
    getOpportunity,
  } = useWorkspace();

  const item = opportunities?.[opp_id] ?? null;

  const {user_status, other_content, user_content, other_status} =
    sortOpportunity(item);

  useEffect(() => {
    if (!item && opp_id) {
      getOpportunity(opp_id);
    }
  }, [opp_id]);

  // LOAD IN OTHER CONTENT
  useEffect(() => {
    if (other_content) {
      const {user_id, content_id, type} = other_content;
      if (type === 'user') {
        loadProfiles([content_id]);
      }
      if (type === 'post') {
        loadProfiles([user_id]);
        loadPosts([content_id]);
      }
    }
    if (user_content) {
      const {user_id, content_id, type} = user_content;
      if (type === 'user') {
        loadProfiles([content_id]);
      }
      if (type === 'post') {
        loadProfiles([user_id]);
        loadPosts([content_id]);
      }
    }
  }, [other_content]);

  // if (status !== 'inactive') {
  //   return null;
  // }

  //TODO: add buttons to set your status

  // USERS
  if (other_content?.type === 'user') {
    const content = profiles?.[other_content?.content_id] ?? {};

    return (
      <>
        <SimpleProfileContent opp={item} content={content} />
      </>
    );
  }

  // POSTS
  if (other_content?.type === 'post') {
    const content = posts?.[other_content?.content_id] ?? {};
    const profile = profiles?.[other_content?.user_id] ?? {};

    return (
      // <>
      //   <PostContent opp={item} content={content} profile={profile} />
      // </>
      null
    );
  }

  return null;
};

//USED IN FIND PARTNER FLOWS
const SimpleProfileContent = ({opp, content, onHover}) => {
  const navigate = useNavigate();
  const {sortOpportunity} = useCollaborator();
  const {loadOrgs} = useLoader();
  const {capitalize, prettyTag, prettyName, prettyPosition} =
    useStringFormatter();

  const {
    state: {posts},
  } = useWorkspace();

  const {
    state: {organizations, current_organization, profiles, profile},
  } = useAuth();

  const {id: opp_id, updatedAt} = opp;
  const {id, email, profile_image, organization_ids, position} = content;

  const other_org = organizations?.[organization_ids?.[0] ?? null] ?? {};

  const {user_status, other_content, user_content, other_status} =
    sortOpportunity(opp);

  const post = posts?.[user_content?.content_id] ?? {};
  const pretty_updated_date = formatMMDDYYYY(convertToDate(updatedAt));
  const pretty_name = prettyName(content);
  const cur_pretty_name = prettyName(profile);

  const pretty_position = prettyPosition(other_org);

  const post_type = capitalize(post?.type ?? 'Project');

  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const [sent, setSent] = useState(false);
  useEffect(() => {
    if (other_content) {
      loadOrgs(organization_ids);
    }
  }, [profiles]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        post.inner_tags = post.inner_tags?.map(x => {
          return prettyTag(x);
        });
        post.outer_tags = post.outer_tags?.map(x => {
          return prettyTag(x);
        });
        const {success, error} = await sendPostFromOppToEmail(
          email,
          post,
          cur_pretty_name,
          post_type,
        );

        if (success) {
          //TODO: update status of opp?
          setSent(true);
          setPopup({
            on: true,
            message: 'Post sent',
          });
          helpers.resetForm();

          track('shared_post', {
            user: id,
            org_id: current_organization,
            sent_to: email,
            post_id: post?.id,
          });
        }

        if (error) {
          setPopup({
            on: true,
            message: 'Error sending post. Try again later.',
          });

          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
          return;
        }
      } catch (err) {
        setPopup({
          on: true,
          message: 'Error. Please reach out to the PublicMind team',
        });
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <div className="base-item-container" onMouseEnter={onHover}>
      <div className="flex-row justify-between">
        <div
          className="flex-column"
          onClick={() => {
            navigate(`/opportunities/detail/${opp_id}`);
          }}>
          <div className="flex-row align-center">
            {id && !isMobile && (
              <ProfileImage
                data={profile_image}
                style={{height: '60px', width: '60px'}}
              />
            )}
            <span className="margin-8" />
            <div className="flex-column">
              <p className="text-left text-bold">{pretty_name}</p>
              <p className="text-left text-secondary">{pretty_position}</p>

              {!isMobile && (
                <p className="text-left text-secondary text-14">
                  Updated: {pretty_updated_date}
                </p>
              )}
            </div>
          </div>
        </div>
        {email ? (
          formik.isSubmitting ? (
            <SpinningIndicator />
          ) : sent ? (
            <div className="create-opportunity-item">
              <FaCheck />
            </div>
          ) : (
            <Tooltip text={'Emails post to member'}>
              <PrimaryButton
                className="create-opportunity-button"
                type="submit"
                disabled={formik.isSubmitting}
                onClick={() => {
                  formik.handleSubmit();
                }}>
                EMAIL
              </PrimaryButton>
            </Tooltip>
          )
        ) : null}
      </div>

      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

export const OpportunityItem = ({item, onHover, inFeed}) => {
  const {sortOpportunity} = useCollaborator();
  const {loadProfiles, loadPosts} = useLoader();

  const {
    state: {id, profiles},
  } = useAuth();
  const {
    state: {posts},
  } = useWorkspace();

  const {user_status, other_content, user_content, other_status} =
    sortOpportunity(item);

  // LOAD IN OTHER CONTENT
  useEffect(() => {
    if (other_content) {
      const {user_id, content_id, type} = other_content;
      if (type === 'user') {
        loadProfiles([content_id]);
      }
      if (type === 'post') {
        loadProfiles([user_id]);
        loadPosts([content_id]);
      }
    }
  }, [other_content]);

  useEffect(() => {
    if (user_content) {
      const {user_id, content_id, type} = user_content;
      if (type === 'user') {
        loadProfiles([content_id]);
      }
      if (type === 'post') {
        loadProfiles([user_id]);
        loadPosts([content_id]);
      }
    }
  }, [user_content]);

  //TODO: add buttons to set your status

  // USERS
  if (other_content?.type === 'user') {
    const content = profiles?.[other_content?.content_id] ?? {};

    return (
      <>
        <ProfileContent
          opp={item}
          content={content}
          onHover={onHover}
          inFeed={inFeed}
        />
      </>
    );
  }

  // POSTS
  if (other_content?.type === 'post') {
    const content = posts?.[other_content?.content_id] ?? {};
    const profile = profiles?.[other_content?.user_id] ?? {};

    return (
      <>
        <PostContent
          opp={item}
          content={content}
          profile={profile}
          onHover={onHover}
          inFeed={inFeed}
        />
      </>
    );
  }

  return null;
};

const ProfileContent = ({opp, content, onHover, inFeed = false}) => {
  const navigate = useNavigate();
  const {prettyTag} = useStringFormatter();
  const {sortOpportunity} = useCollaborator();
  const {trackInteraction} = useTracker();
  const {
    state: {organizations},
  } = useAuth();
  const {
    state: {posts, new_opps},
    handleOpportunity,
  } = useWorkspace();
  const [show, setShow] = useState(false);

  const {id: opp_id, tags, updatedAt, createdAt, data} = opp;

  const {id, first_name, last_name, position, profile_image, org_id} = content;
  const org = organizations?.[org_id] ?? {};
  const {name} = org;
  const {user_status, other_content, user_content, other_status} =
    sortOpportunity(opp);

  const mobile = isMobile;
  const post = posts?.[user_content?.content_id] ?? {};

  const pretty_updated_date = formatMMDDYYYY(convertToDate(updatedAt));
  const pretty_created_date = formatMMDDYYYY(convertToDate(createdAt));

  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`
      : other_content.user_id;

  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const handleOpp = async stat => {
    // MAP DATA
    const mapped_data = data.map(d => {
      const {user_id, notes, status, updated, email_timestamp} = d;
      if (user_id === id) {
        return {
          user_id,
          notes,
          status: stat,
          updated: dateToTimestamp(),
          email_timestamp,
        };
      }
      return {user_id, notes, status, updated, email_timestamp};
    });
    const update = {
      id: opp_id,
      status:
        stat === 'ignore'
          ? 'closed'
          : stat === 'inactive'
          ? 'inactive'
          : 'active',
      data: mapped_data,
    };
    // UPDATE OPP
    const {success, error} = await handleOpportunity(update);

    if (success) {
      opp.status = stat === 'ignore' ? 'closed' : 'active';
      track('opportunity_interaction', {
        updated_status: stat,
        user_id: id,
        opportunity_id: opp_id,
        opportunity: opp,
      });
      trackInteraction({
        target_user: other_content.user_id,
        content_id: opp_id,
        content_type: 'opportunity',
        action: stat,
      });
    }
  };

  return (
    <div
      className={
        opp?.status === 'closed' && inFeed
          ? 'update-container opacity-50'
          : opp?.status === 'active' && inFeed
          ? 'update-container connect-border'
          : 'update-container'
      }
      onMouseEnter={onHover}>
      <div className="flex-column fullwidth">
        <div className="flex-row justify-between align-center">
          {!other_status.notes ? (
            <p className="text-left text-secondary text-12 width-75">
              <span className="flex-row align-center">
                <Label label={'Opportunity'} color={'#4743c5'} />
                {post?.title ? `from "${post?.title}"` : 'from Profile'}
              </span>
            </p>
          ) : (
            <p className="text-left text-secondary text-12 width-75">
              Message from {pretty_name}{' '}
              {post?.title ? `on "${post?.title}"` : ''}
            </p>
          )}
          {!inFeed && (
            <div className="align-end">
              {' '}
              <Tooltip text={'See Details'}>
                <span className="flex-column align-center">
                  <RxEnter
                    onClick={() => {
                      navigate(`/opportunities/detail/${opp_id}`);
                    }}
                    size={'15px'}
                    className="clickable"
                  />
                  <p className="text-secondary text-8">{'Details'}</p>
                </span>
              </Tooltip>
            </div>
          )}
          {!mobile && inFeed && opp?.status === 'inactive' && (
            <QuickButtons
              onSkip={async () => {
                await handleOpp('ignore');
              }}
              onConnect={() => {
                setShow(!show);
              }}
              onView={() => {
                navigate(`/opportunities/detail/${opp_id}`);
              }}
            />
          )}
          {inFeed && opp?.status === 'active' && (
            <p className="border-bottom">Connect Request Sent</p>
          )}
          {inFeed && opp?.status === 'closed' && (
            <Tooltip text={'Click to undo skip'}>
              <p
                className="border-bottom"
                onClick={async () => {
                  await handleOpp('inactive');
                }}>
                Skipped
              </p>
            </Tooltip>
          )}
        </div>
        {other_status.notes && (
          <>
            <p className="message-border">{other_status.notes}</p>
            <br />
          </>
        )}
        <div className="flex-row">
          <div className="flex-column">
            <div className="flex-row align-center">
              {id && (
                <ProfileImage
                  data={profile_image}
                  style={{height: '45px', width: '45px'}}
                  onClick={() => {
                    navigate(`/feed/profiles/detail/${id}`);
                  }}
                />
              )}
              <span className="margin-8" />
              <div className="flex-column">
                <p className="text-left text-bold">{pretty_name}</p>
                <p
                  className="text-left text-14 text-secondary"
                  onClick={e => {
                    e.stopPropagation();
                    navigate(`/feed/organizations/detail/${org_id}`);
                  }}>
                  {position} {name}
                </p>
                <p className="text-left text-secondary text-14">
                  Created: {pretty_created_date}
                </p>
              </div>
            </div>

            <div className="flex-row-wrap padding-top8">
              {tags.slice(0, 12).map(tag => {
                return (
                  <p key={tag} className="bubble-small" onClick={() => {}}>
                    {prettyTag(tag)}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        {mobile && (
          <QuickButtons
            onSkip={async () => {
              await handleOpp('ignore');
            }}
            onConnect={() => {
              setShow(!show);
            }}
            onView={() => {
              navigate(`/opportunities/detail/${opp_id}`);
            }}
          />
        )}
      </div>
      <ScheduleModal item={opp} active={show} setActive={setShow} />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

const QuickButtons = ({onSkip, onConnect, onView}) => {
  return (
    <div className="flex-row justify-end align-center">
      <span className="padding-right4">
        <Tooltip text={'Skip'}>
          <span className="flex-column align-center">
            <RiCloseFill size={'18px'} onClick={onSkip} className="clickable" />
            <p className="text-secondary text-8">{'Skip'}</p>
          </span>
        </Tooltip>
      </span>
      <span className="padding-right4">
        <Tooltip text={'Match'}>
          <span className="flex-column align-center">
            <RxLightningBolt
              size={'18px'}
              onClick={onConnect}
              className="clickable"
            />
            <p className="text-secondary text-8">{'Match'}</p>
          </span>
        </Tooltip>
      </span>
      <span>
        <Tooltip text={'See Details'}>
          <span className="flex-column align-center">
            <RxEnter onClick={onView} size={'18px'} className="clickable" />
            <p className="text-secondary text-8">{'Details'}</p>
          </span>
        </Tooltip>
      </span>
    </div>
  );
};

const PostContent = ({opp, content, profile, onHover, inFeed = false}) => {
  const navigate = useNavigate();
  const {prettyTag} = useStringFormatter();
  const {trackInteraction} = useTracker();
  const {sortOpportunity} = useCollaborator();
  const {handleOpportunity} = useWorkspace();

  const [show, setShow] = useState(false);
  const mobile = isMobile;

  const {id: opp_id, tags, updatedAt, data} = opp;
  const {id, description, title} = content;
  const {profile_image, first_name, last_name, id: profile_id} = profile;

  const {user_status, other_content, user_content, other_status} =
    sortOpportunity(opp);

  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`
      : other_content.user_id;
  const pretty_updated_date = formatMMDDYYYY(convertToDate(updatedAt));

  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });
  const handleOpp = async stat => {
    // MAP DATA
    const mapped_data = data.map(d => {
      const {user_id, notes, status, updated, email_timestamp} = d;
      if (user_id === id) {
        return {
          user_id,
          notes,
          status: stat,
          updated: dateToTimestamp(),
          email_timestamp,
        };
      }
      return {user_id, notes, status, updated, email_timestamp};
    });
    const update = {
      id: opp_id,
      status:
        stat === 'ignore'
          ? 'closed'
          : stat === 'inactive'
          ? 'inactive'
          : 'active',
      data: mapped_data,
    };
    // UPDATE OPP
    const {success, error} = await handleOpportunity(update);

    if (success) {
      opp.status = stat === 'ignore' ? 'closed' : 'active';
      track('opportunity_interaction', {
        updated_status: stat,
        user_id: id,
        opportunity_id: opp_id,
        opportunity: opp,
      });
      trackInteraction({
        target_user: other_content.user_id,
        content_id: opp_id,
        content_type: 'opportunity',
        action: stat,
      });
    }
  };

  return (
    <div
      className={
        opp?.status === 'closed' && inFeed
          ? 'update-container opacity-50'
          : opp?.status === 'active' && inFeed
          ? 'update-container connect-border'
          : 'update-container'
      }
      onMouseEnter={onHover}>
      <div className="flex-column fullwidth">
        <div className="flex-row justify-between align-center">
          {!other_status.notes ? (
            <p className="text-left text-secondary text-12 width-75">
              Opportunity {content?.title ? `with "${content?.title}"` : ''}
            </p>
          ) : (
            <p className="text-left" />
          )}
          {!inFeed && (
            <div className="align-end">
              {' '}
              <Tooltip text={'See Details'}>
                <span className="flex-column align-center">
                  <RxEnter
                    onClick={() => {
                      navigate(`/opportunities/detail/${opp_id}`);
                    }}
                    size={'15px'}
                    className="clickable"
                  />
                  <p className="text-secondary text-8">{'Details'}</p>
                </span>
              </Tooltip>
            </div>
          )}
          {!mobile && inFeed && opp?.status === 'inactive' && (
            <QuickButtons
              onSkip={async () => {
                await handleOpp('ignore');
              }}
              onConnect={() => {
                setShow(!show);
              }}
              onView={() => {
                navigate(`/opportunities/detail/${opp_id}`);
              }}
            />
          )}
          {inFeed && opp?.status === 'active' && (
            <p className="border-bottom">Connect Request Sent</p>
          )}
          {inFeed && opp?.status === 'closed' && (
            <Tooltip text={'Click to undo skip'}>
              <p
                className="border-bottom"
                onClick={async () => {
                  await handleOpp('inactive');
                }}>
                Skipped
              </p>
            </Tooltip>
          )}
        </div>
        {content && <SimplePostItem item={content} />}
        <div className="flex-row padding-top8">
          <div className="flex-column">
            <div className="flex-row align-center">
              {profile_id && (
                <ProfileImage
                  data={profile_image}
                  style={{height: '45px', width: '45px'}}
                  onClick={() => {
                    navigate(`/feed/profiles/detail/${profile_id}`);
                  }}
                />
              )}
              <span className="margin-8" />
              <div className="flex-column">
                <p className="text-left text-bold">{pretty_name}</p>

                {other_status.notes && (
                  <p className="text-left">Note: {other_status.notes}</p>
                )}
                {/* <p className="text-left">Source: User Post</p> */}
                <p className="text-left text-secondary text-14">
                  Updated: {pretty_updated_date}
                </p>
              </div>
            </div>
            <div className="flex-row-wrap padding-top8">
              {tags.slice(0, 12).map(tag => {
                return (
                  <p key={tag} className="bubble-small" onClick={() => {}}>
                    {prettyTag(tag)}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        {mobile && (
          <QuickButtons
            onSkip={async () => {
              await handleOpp('ignore');
            }}
            onConnect={() => {
              setShow(!show);
            }}
            onView={() => {
              navigate(`/opportunities/detail/${opp_id}`);
            }}
          />
        )}
      </div>
      <ScheduleModal item={opp} active={show} setActive={setShow} />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};
