import React from 'react';
import {RxEnter} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';
import Tooltip from '../tooltip/tooltip';

export const GroupItem = ({item, index, clickable}) => {
  const navigate = useNavigate();
  const {capitalize} = useStringFormatter();
  const {id, name, bio, members, tags, image, type} = item;

  const {
    state: {id: user_id, profile, current_group},
    updateProfile,
    defaultUpdate,
  } = useAuth();
  const {defaultUpdate: update} = useWorkspace();

  const {group_ids} = profile || {};

  const initial = group_ids?.[0] === id;

  const prettyTypes = type => {
    switch (type) {
      case 'public':
        return 'Private';
      default:
        return capitalize(type);
    }
  };

  return (
    <div className={`card ${initial ? 'border' : ''}`}>
      <div className="flex-row justify-between align-center">
        <div className="flex-row align-center">
          {image?.url && (
            <img
              src={image?.url}
              alt=""
              style={{height: '32px', width: '32px', marginRight: '8px'}}
            />
          )}
          <h4 className="">{name}</h4>
        </div>
        {clickable && (
          <div className="align-end">
            <Tooltip text={'See Details'}>
              <span className="flex-column align-center">
                <RxEnter
                  onClick={() => {
                    navigate(`/groups/detail/${id}`);
                  }}
                  size={'15px'}
                />
                <p className="text-secondary text-10">{'Details'}</p>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <p className="text-left">{bio}</p>
      <p className="text-left text-secondary text-14">
        Members: {members?.length ?? 0}
      </p>
      <p className="text-left text-secondary text-14">
        Group Type: {prettyTypes(type || 'public')}
      </p>
      <div className="flex-row-wrap padding-top8">
        {tags.slice(0, 12).map(tag => {
          return (
            <p key={tag} className="bubble" onClick={() => {}}>
              {tag}
            </p>
          );
        })}
      </div>
      {!!index && !initial && (
        <p
          className="text-link text-left cursor-pointer"
          onClick={async e => {
            e.stopPropagation();
            const grps = [...(group_ids || [])];
            const [removedItem] = grps.splice(index, 1);
            grps.unshift(removedItem);

            await updateProfile({id: user_id, group_ids: grps});
            track('default_group_updated', {group_id: id});

            const active = current_group === id;
            if (!active) {
              defaultUpdate({
                current_group: id,
                profiles_loaded: false,
                profile_token: null,
                orgs_loaded: false,
                org_token: null,
              });
              update({
                posts_loaded: false,
                post_token: null,
                feed_loaded: false,
                feed_token: null,
                new_opps_loaded: false,
                opp_token: null,
                feed: [],
                post_feed: [],
                user_posts: {},
              });
              track('current_group_updated', {group_id: id});
            }
          }}>
          Set Group as Default
        </p>
      )}
    </div>
  );
};
