import React, {useEffect} from 'react';
import {dateToTimestamp} from '../../api/dates';
import constants from '../../components/constants';
import {GroupItem} from '../../components/item-details/group-item';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import {useWorkspace} from '../../hooks/use-workspace';

const GroupsOverview = ({}) => {
  const {
    state: {id, profile, groups, invitations, invitations_loaded},
    getInvitations,
  } = useAuth();

  const {group_ids} = profile || {};

  useEffect(() => {
    if (!invitations_loaded) {
      getInvitations({
        filter: {invitee_id: {eq: id}, status: {eq: 'pending'}},
        limit: constants.all_items,
      });
    }
  }, []);

  return (
    <div>
      <div className="grid-container">
        <div className="grid-8">
          {group_ids?.length ? (
            group_ids.map((group_id, i) => {
              return (
                <GroupItem
                  key={group_id}
                  item={groups?.[group_id] ?? {}}
                  index={i}
                  className="clickable"
                  clickable={true}
                />
              );
            })
          ) : (
            <div className="card">
              <p>You are not part of a group.</p>
              <p>
                Reach out to the PublicMind team using the black circle in the
                bottom right.
              </p>
            </div>
          )}
        </div>
        <div className="grid-4">
          <div className="card">
            <h4>Invites</h4>
            {invitations?.length ? (
              invitations.map(invite => {
                const {id, group_id} = invite;
                return <InviteItem key={id} item={invite} />;
              })
            ) : (
              <p>No invites</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const InviteItem = ({item}) => {
  const {loadGroups} = useLoader();

  const {group_id, id, admin} = item;

  const {
    state: {id: user_id, groups, profile},
    updateGroup,
    updateProfile,
    updateInvitation,
  } = useAuth();
  const {manageOpportunities} = useWorkspace();

  useEffect(() => {
    loadGroups([group_id]);
  }, []);

  const group = groups?.[group_id] ?? {};

  const {group_ids} = profile || {};

  const {name, members, admins, bio} = group;

  return (
    <div key={group_id}>
      <p className="text-left">{name}</p>
      <p className="text-left text-secondary text-12 text-preview">{bio}</p>
      <p className="text-left text-secondary text-12">
        Members: {members?.length ?? 0}
      </p>
      <button
        className="update-container-special-add"
        onClick={async () => {
          const mem = [...(members || [])];
          if (!mem.includes(user_id)) {
            mem.push(user_id);
          }

          const adm = [...(admins || [])];
          if (admin && !adm.includes(user_id)) {
            adm.push(user_id);
          }

          const grp = [...(group_ids || [])];
          if (!grp.includes(group_id)) {
            grp.push(group_id);
          }

          const {success, error} = await updateGroup({
            id: group_id,
            updated: dateToTimestamp(),
            members: mem,
            admins: adm,
          });
          if (success) {
            const {success, error} = await updateProfile({
              id: user_id,
              group_ids: grp,
            });
            await updateInvitation({
              id,
              status: 'accepted',
              updated: dateToTimestamp(),
            });
            const body = {
              id: user_id,
              type: 'user',
              inner_added: profile?.inner_tags ?? [],
              inner_removed: [],
              outer_added: profile?.outer_tags ?? [],
              outer_removed: [],
              group_ids: grp,
            };
            manageOpportunities(body, id);
            console.log('SUCCESS');
          }
        }}>
        Accept
      </button>
    </div>
  );
};

export default GroupsOverview;
