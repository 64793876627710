import React, {useEffect, useState} from 'react';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import constants from '../constants';
import {Textfield} from '../inputs/textfields';
import './styles.css';

export const GroupFilter = ({setLoading}) => {
  const {
    state: {profile, group_filter},
    searchGroups,
  } = useAuth();

  const [editFilter, setFilter] = useState(group_filter);

  const search = async (filter, options) => {
    setLoading(true);
    const {success, error, data} = await searchGroups(filter, options);
    setLoading(false);
    track('search', {
      source: 'groups',
      filter,
      options,
    });
    return {success, error, data};
  };

  useEffect(() => {
    if (group_filter) {
      setFilter(group_filter);
    }
  }, [group_filter]);

  return (
    <div className="filter-container">
      <h4>Preferences</h4>
      <Textfield
        value={editFilter.name}
        type="text"
        onChange={e => setFilter({...editFilter, name: e.target.value})}
        header="Name"
      />
      <Textfield
        value={editFilter.bio}
        type="text"
        onChange={e => setFilter({...editFilter, bio: e.target.value})}
        header="Description"
      />

      <div className="flex-row justify-center">
        <button
          onClick={async () => {
            setLoading(true);
            const options = {limit: constants.org_search_limit};
            await search(editFilter, options);
            setLoading(false);
          }}>
          Search
        </button>
      </div>
    </div>
  );
};
