const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const isSameDay = (timestamp1, timestamp2) => {
  const date1 = new Date(timestamp1 * 1000);
  const date2 = new Date(timestamp2 * 1000);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const convertToDate = obj => {
  if (!obj) {
    return null;
  }
  const type = typeof obj;
  switch (type) {
    case 'object':
      // TODO: used for firebase need to update for AWS?
      return obj instanceof Date ? obj : obj.toDate();
    case 'string':
    case 'number':
      return new Date(obj);
    default:
      return obj;
  }
};

const dateToString = (date = new Date()) => {
  return date.toISOString().slice(0, 10);
};

const timeToString = (date = new Date()) => {
  return date.toISOString().slice(11, 16);
};

// TAKES IN A STRING
const formatDate = (
  date,
  options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
) => {
  const [year, month, day] = date.split('-').map(num => parseInt(num, 10));
  // Note: JavaScript months are 0-indexed, so subtract 1 from the month value.
  const formattedDate = new Date(year, month - 1, day).toLocaleDateString(
    'en-US',
    options,
  );
  return formattedDate;
};

// TAKES IN A DATE OBJ
export const dateFormatter = (
  date = new Date(),
  options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  return formattedDate;
};

const formatTime = time => {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Convert hours to 12-hour format
  const twelveHourFormat = hours % 12 || 12;

  // Determine if it's AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Construct the formatted time string
  const formattedTime = `${twelveHourFormat}:${minutes
    .toString()
    .padStart(2, '0')}${period}`;

  return formattedTime;
};

// Sets the year month day relative to the current day
const goBackInTimeFromDate = (data, date = new Date()) => {
  const {years, months, days} = data;
  const newDate = new Date(
    date.getFullYear() - (years || 0),
    date.getMonth() - (months || 0),
    date.getDate() - (days || 0),
  );
  return newDate;
};

const formatMMDDYYYY = (date = new Date()) => {
  try {
    const months = date.getMonth() + 1;
    const days = date.getDate();
    const years = date.getFullYear();
    return `${months}/${days}/${years}`;
  } catch (err) {
    return null;
  }
};

const formatWWW_MMM_DD_YYYY_TTTT = (date = new Date()) => {
  let stringify = date.toString();
  stringify = stringify.slice(0, 21);
  return stringify;
};

const formatWWW_DD_MMM_TTT = (date = new Date()) => {
  const day = date.getDate(); // Gets the day of the month
  const monthIndex = date.getMonth(); // Gets the month index (0-11)
  const hour = date.getHours(); // Gets the hour (0-23)
  const minute = date.getMinutes(); // Gets the minutes (0-59)

  const formattedHour = hour % 12 || 12; // Convert to 12-hour format
  const formattedMinute = String(minute).padStart(2, '0'); // Add leading zeros
  const ampm = hour >= 12 ? 'PM' : 'AM';

  return `${day} ${months[monthIndex]} ${formattedHour}:${formattedMinute} ${ampm}`;
};

const formatChainDate = inputDate => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const targetDate = new Date(inputDate);

  // Resetting the time to compare only the date
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  if (targetDate.getTime() === today.getTime()) {
    return 'Today';
  }

  if (targetDate.getTime() === yesterday.getTime()) {
    return 'Yesterday';
  }

  const day = targetDate.getDate().toString().padStart(2, '0');
  const month = (targetDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because JavaScript months are 0-based
  const year = targetDate.getFullYear();

  return `${month}/${day}/${year}`;
};

const getCurrentYearAndWeek = (date = new Date()) => {
  const year = date.getFullYear();
  const dayOfYear = Math.floor((date - new Date(year, 0, 0)) / 86400000);
  const week = Math.ceil((dayOfYear + 1) / 7); // Adding 1 to account for January 1st

  return `${year}-${String(week).padStart(2, '0')}`;
};

const dateToTimestamp = (date = new Date()) => {
  return Math.floor(date.getTime() / 1000);
};

const awsTimestampToDate = awsTimestamp => {
  return new Date(awsTimestamp * 1000);
};

const isWithinLast24Hours = timestamp => {
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const now = new Date();
  const timestampDate = new Date(timestamp);

  return now - timestampDate < oneDay;
};

const timeSince = timestamp => {
  const timestampDate = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript date
  const now = new Date();
  const timeDiffInSeconds = Math.floor((now - timestampDate) / 1000); // Difference in seconds
  const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60); // Convert to minutes
  const timeDiffInHours = Math.floor(timeDiffInMinutes / 60); // Convert to hours
  const timeDiffInDays = Math.floor(timeDiffInHours / 24); // Convert to days
  const timeDiffInWeeks = Math.floor(timeDiffInDays / 7); // Convert to weeks

  if (timeDiffInSeconds < 60) {
    return `${timeDiffInSeconds} second${
      timeDiffInSeconds !== 1 ? 's' : ''
    } ago`;
  } else if (timeDiffInMinutes < 60) {
    return `${timeDiffInMinutes} minute${
      timeDiffInMinutes !== 1 ? 's' : ''
    } ago`;
  } else if (timeDiffInHours < 24) {
    return `${timeDiffInHours} hour${timeDiffInHours !== 1 ? 's' : ''} ago`;
  } else if (timeDiffInDays < 7) {
    return `${timeDiffInDays} day${timeDiffInDays !== 1 ? 's' : ''} ago`;
  } else if (timeDiffInWeeks < 4) {
    return `${timeDiffInWeeks} week${timeDiffInWeeks !== 1 ? 's' : ''} ago`;
  } else {
    return timestampDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }
};

const formatEventDate = values => {
  if (!values) {
    return null;
  }
  const {start_date, start_time, end_date, end_time} = values;
  let pretty = '';

  if (start_date) {
    pretty += formatDate(start_date);
  }

  if (start_time) {
    pretty += `: ${formatTime(start_time)}`;
  }

  if (end_date) {
    pretty += ` - ${formatDate(end_date)}`;
  }

  if (end_time) {
    pretty += `: ${formatTime(end_time)}`;
  }

  return pretty;
};

const timestampToTimeOrDate = timestamp => {
  const date = new Date(timestamp);
  const today = new Date();

  const isSameDay =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  if (isSameDay) {
    // If the timestamp is the same day as today, return the time in HH:MM format
    return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  } else {
    // If the timestamp is not the same day as today, return the date in MM/DD/YYYY format
    return date.toLocaleDateString();
  }
};

const daysBetweenTodayAndTimestamp = timestamp => {
  if (!timestamp) return null;

  const timestampInMilliseconds = timestamp * 1000;

  const date = new Date(timestampInMilliseconds);

  const currentDate = new Date();

  const differenceInTime = date - currentDate;

  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export {
  awsTimestampToDate,
  convertToDate,
  dateToString,
  dateToTimestamp,
  daysBetweenTodayAndTimestamp,
  formatChainDate,
  formatDate,
  formatEventDate,
  formatMMDDYYYY,
  formatTime,
  formatWWW_DD_MMM_TTT,
  formatWWW_MMM_DD_YYYY_TTTT,
  getCurrentYearAndWeek,
  goBackInTimeFromDate,
  isSameDay,
  isWithinLast24Hours,
  timeSince,
  timeToString,
  timestampToTimeOrDate,
};
