import {useGoogleLogin} from '@react-oauth/google';
import React, {useEffect, useState} from 'react';
import {RxOpenInNewWindow} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {fetchGoogleProfile} from '../../api/google';
import {Checkbox} from '../../components/inputs/checkbox';
import {AuthHeader} from '../../components/layout/layout';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {TooltipNoShadow} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import useEmail from '../../hooks/use-email';
import useGoogle from '../../hooks/use-google';
import useLoader from '../../hooks/use-loader';
import {useNetworking} from '../../hooks/use-networking';
import useSearchParameters from '../../hooks/use-search-parameters';

const ConnectEmail = ({}) => {
  const navigate = useNavigate();
  const {importEmails} = useGoogle();
  const {dataToParams} = useSearchParameters();
  const {extractEmail, isPersonalEmail, categorizeEmails} = useEmail();
  const {loadProfiles, loadRolodex} = useLoader();

  const {
    state: {id, profiles},
  } = useAuth();
  const {
    state: {rolodex, rolodex_content, rolodex_loaded},
    createEntry,
    fetchRolodex,
  } = useNetworking();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profile_meta, setProfile] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(20);

  useEffect(() => {
    loadRolodex();
  }, []);

  useEffect(() => {
    const load = async () => {
      let emails = contacts.map(obj => obj.key);
      await loadProfiles(emails);
    };
    if (contacts.length > 0) {
      load();
    }
  }, [contacts]);

  const login = useGoogleLogin({
    onSuccess: async res => {
      try {
        setLoading(true);
        const {access_token} = res;

        const prof = await fetchGoogleProfile(access_token);
        const meta = await importEmails(access_token);

        const contacts = categorizeEmails(meta, prof.email);

        setContacts(contacts);
        setProfile(prof);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    },
    onError: err => {
      setError('Login Failed');
      console.log('Login Failed', err);
    },
    scope:
      'phone email openid profile https://www.googleapis.com/auth/gmail.metadata',
  });

  return (
    <div className="page-container">
      <div className="flex-column update-container">
        <div className="flex-column align-center justify-center">
          <AuthHeader
            header={
              loading
                ? 'Please hold for a moment'
                : profile_meta
                ? 'Your Network is ready'
                : 'Welcome to PublicMind Notes Beta'
            }
            subheader={
              profile_meta
                ? 'Great work. Now its time to create a few notes.'
                : `Notes Beta is powered by your personal network. The first step is to generate your network via our secure integration with Gmail.`
            }
          />
          {!loading && !profile_meta && (
            <p className="text-secondary text-12 width-50">
              We value your privacy and ensure that your data remains yours. By
              accessing only email header information, we help you understand
              your current network while unlocking more of PublicMind's powerful
              tools. Rest assured, no other members can see or access your data.
            </p>
          )}
        </div>
        {loading ? (
          <SpinningIndicator />
        ) : (
          <div className="flex-column align-center justify-center">
            {!profile_meta ? (
              <div className="flex-column align-center justify-center">
                <button className="button-container" onClick={login}>
                  Authorize via Google
                  <RxOpenInNewWindow className="padding-left8" size={'20px'} />
                </button>
                {error && <p className="error">{error}</p>}
              </div>
            ) : (
              <div className="">
                {contacts?.slice(0, page).map((contact, index) => {
                  const {key, value, active} = contact;
                  const exists = rolodex.find(id => {
                    return rolodex_content[id]?.emails?.includes(key);
                  });
                  return (
                    <ConnectItem
                      key={key}
                      disabled={exists}
                      item={contact}
                      onChange={e => {
                        if (exists) {
                          return;
                        }
                        const val = e.target.checked;
                        const updatedContacts = contacts?.map((contact, i) => {
                          if (i === index) {
                            // Update the active property based on checkbox value
                            return {...contact, active: val};
                          }
                          return contact;
                        });
                        setContacts(updatedContacts);
                      }}
                    />
                  );
                })}
                <div className="flex-row justify-center align-center">
                  {contacts?.length > page && (
                    <button
                      onClick={() => {
                        setPage(page + 20);
                      }}>
                      See More
                    </button>
                  )}
                  <button
                    className="button-container"
                    onClick={async () => {
                      const invites = contacts
                        ?.filter(contact => contact.active)
                        .map(item => item.key);

                      await Promise.all(
                        invites?.map(async email => {
                          const profile = profiles?.[email];
                          const now = dateToTimestamp();
                          const rolodex = {
                            id: v4(),
                            source_user: id,
                            updated: now,
                            created: now,
                            first_name: profile?.first_name ?? null,
                            last_name: profile?.last_name ?? null,
                            emails: [email],
                            job_title: profile?.position ?? null,
                            company: null,
                            locations: [],
                            inner_tags: [],
                            outer_tags: [],
                            social_links: [],
                            notes: [],
                          };

                          await createEntry(rolodex);
                          track('rolodex_entry_created', {user: id, rolodex});
                        }),
                      );

                      navigate('/notes');
                    }}>
                    Create Notes
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const ConnectEmailComponent = ({}) => {
  const navigate = useNavigate();
  const {importEmails} = useGoogle();
  const {dataToParams} = useSearchParameters();
  const {extractEmail, isPersonalEmail, categorizeEmails} = useEmail();
  const {loadProfiles, loadRolodex} = useLoader();

  const {
    state: {id, profiles},
  } = useAuth();
  const {
    state: {rolodex, rolodex_content, rolodex_loaded},
    createEntry,
    fetchRolodex,
  } = useNetworking();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profile_meta, setProfile] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(20);

  useEffect(() => {
    loadRolodex();
  }, []);

  useEffect(() => {
    const load = async () => {
      let emails = contacts.map(obj => obj.key);
      await loadProfiles(emails);
    };
    if (contacts.length > 0) {
      load();
    }
  }, [contacts]);

  const login = useGoogleLogin({
    onSuccess: async res => {
      try {
        setLoading(true);
        const {access_token} = res;

        const prof = await fetchGoogleProfile(access_token);
        const meta = await importEmails(access_token);

        const contacts = categorizeEmails(meta, prof.email);

        setContacts(contacts);
        setProfile(prof);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    },
    onError: err => {
      setError('Login Failed');
      console.log('Login Failed', err);
    },
    scope:
      'phone email openid profile https://www.googleapis.com/auth/gmail.metadata',
  });

  return (
    <div className="flex-column update-container">
      <div className="flex-column align-center justify-center">
        <AuthHeader
          header={
            loading
              ? 'Please hold for a moment'
              : profile_meta
              ? 'Your Network is ready'
              : 'Welcome to PublicMind Notes Beta'
          }
          subheader={
            profile_meta
              ? 'Great work. Now its time to create a few notes.'
              : `Notes Beta is powered by your personal network. The first step is to generate your network via our secure integration with Gmail.`
          }
        />
        {!loading && !profile_meta && (
          <p className="text-secondary text-12 width-50">
            We value your privacy and ensure that your data remains yours. By
            accessing only email header information, we help you understand your
            current network while unlocking more of PublicMind's powerful tools.
            Rest assured, no other members can see or access your data.
          </p>
        )}
      </div>
      {loading ? (
        <SpinningIndicator />
      ) : (
        <div className="flex-column align-center justify-center">
          {!profile_meta ? (
            <div className="flex-column align-center justify-center">
              <TooltipNoShadow
                text={`Use "Search Members" if you prefer not to use Gmail`}>
                <button className="button-container" onClick={login}>
                  Authorize via Google
                  <RxOpenInNewWindow className="padding-left8" size={'20px'} />
                </button>
              </TooltipNoShadow>
              <TooltipNoShadow text={'No third-party integration required'}>
                <button
                  className="button"
                  onClick={() => {
                    navigate('/feed', {state: {start_index: 1}});
                  }}>
                  Search Members
                </button>
              </TooltipNoShadow>
              {error && <p className="error">{error}</p>}
            </div>
          ) : (
            <div className="">
              {contacts?.slice(0, page).map((contact, index) => {
                const {key, value, active} = contact;
                const exists = rolodex.find(id => {
                  return rolodex_content[id]?.emails?.includes(key);
                });
                return (
                  <ConnectItem
                    key={key}
                    disabled={exists}
                    item={contact}
                    onChange={e => {
                      if (exists) {
                        return;
                      }
                      const val = e.target.checked;
                      const updatedContacts = contacts?.map((contact, i) => {
                        if (i === index) {
                          // Update the active property based on checkbox value
                          return {...contact, active: val};
                        }
                        return contact;
                      });
                      setContacts(updatedContacts);
                    }}
                  />
                );
              })}
              <div className="flex-row justify-center align-center">
                {contacts?.length > page && (
                  <button
                    onClick={() => {
                      setPage(page + 20);
                    }}>
                    See More
                  </button>
                )}
                <button
                  className="button-container"
                  onClick={async () => {
                    const invites = contacts
                      ?.filter(contact => contact.active)
                      .map(item => item.key);

                    await Promise.all(
                      invites?.map(async email => {
                        const profile = profiles?.[email];
                        const now = dateToTimestamp();
                        const rolodex = {
                          id: v4(),
                          source_user: id,
                          updated: now,
                          created: now,
                          first_name: profile?.first_name ?? null,
                          last_name: profile?.last_name ?? null,
                          emails: [email],
                          job_title: profile?.position ?? null,
                          company: null,
                          locations: [],
                          inner_tags: [],
                          outer_tags: [],
                          social_links: [],
                          notes: [],
                        };

                        await createEntry(rolodex);
                        track('rolodex_entry_created', {user: id, rolodex});
                      }),
                    );

                    navigate('/notes');
                  }}>
                  Create Notes
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ConnectItem = ({item, onChange, disabled}) => {
  const {
    state: {profiles},
  } = useAuth();

  const {key, value, active} = item;
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    let email = key.toLowerCase();
    if (profiles?.[email]) {
      if (!disabled) {
        item.active = true;
      }
      setIsMember(true);
    }
  }, [profiles]);

  return (
    <>
      {isMember ? (
        <div className="flex-row align-center justify-between" key={key}>
          <div className="flex-row align-center">
            <Checkbox
              checked={active}
              onChange={onChange}
              disabled={disabled}
            />
            <MemberProfile email={key} profiles={profiles} />
          </div>
          <p className="padding-left8">{value}</p>
        </div>
      ) : (
        <div className="flex-row align-center justify-between" key={key}>
          <div className="flex-row align-center">
            <Checkbox
              checked={active}
              onChange={onChange}
              disabled={disabled}
            />
            <p>{key.length > 50 ? `${key.slice(0, 40)}...` : key}</p>
          </div>
          <p className="padding-left8">{value}</p>
        </div>
      )}
    </>
  );
};

const MemberProfile = ({profiles, email}) => {
  const user = profiles?.[email] ?? {};
  const {first_name, last_name} = user;
  return (
    <>
      <p className="text-bold">
        {first_name} {last_name}
      </p>
      <p className="text-12 text-secondary padding-left8">
        (PublicMind Member)
      </p>
    </>
  );
};

export default ConnectEmail;
