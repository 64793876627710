import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {FaChevronDown, FaHouse} from 'react-icons/fa6';
import {MdLogout} from 'react-icons/md';
import {
  RxCardStack,
  RxEnvelopeClosed,
  RxFileText,
  RxInfoCircled,
  RxLightningBolt,
  RxMagnifyingGlass,
  RxMix,
  RxMoon,
  RxPaperPlane,
  RxQuestionMark,
  RxSun,
} from 'react-icons/rx';
import QRCode from 'react-qr-code';
import {Outlet, useNavigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import '../../App.css';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLogout from '../../hooks/use-logout';
import {useMessage} from '../../hooks/use-message';
import useSearchParameters from '../../hooks/use-search-parameters';
import {useWorkspace} from '../../hooks/use-workspace';
import CreatePost from '../../pages/posts/create-post';
import MobileCreatePost from '../../pages/posts/create-post-mobile';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {Badge, BadgeDot} from '../badge/badge';
import {LinkButton} from '../buttons/buttons';
import constants from '../constants';
import {PublicMindLogoLightNoText} from '../images/logos';
import {DropDown} from '../inputs/drop-down';
import {ProfileImage} from '../inputs/uploader';
import {NetworkBackground} from '../loading/network-background';
import {InviteGroupModal} from '../modal/invite-group-modal';
import {Modal} from '../modal/modal';
import {InvertedTooltip, TooltipRight} from '../tooltip/tooltip';
import './styles.css';

export const PublicLayout = props => {
  const {children} = props;

  return (
    <div className="base-container align-center">
      <div className="public-layout">{children || <Outlet />}</div>
    </div>
  );
};

export const AuthLayout = props => {
  const {children} = props;

  return (
    <div className="base-container auth-layout">
      <Sidebar disabled={true} />
      {children || <Outlet />}
      <NetworkBackground />
      <Footer />
    </div>
  );
};

export const FeedLayout = props => {
  const {children} = props;

  return (
    <>
      <div className="base-container">
        <Sidebar />
        <FeedHeader />
        <div className={'feed-layout'}>
          {children || <Outlet />}
          <Footer />
        </div>
      </div>
    </>
  );
};

const Sidebar = ({disabled}) => {
  const navigate = useNavigate();
  const {showSpace} = useIntercom();

  const {
    state: {id, profile, current_group, groups},
  } = useAuth();
  const {
    state: {chains, chain_ids},
  } = useMessage();

  const [add, setAdd] = useState(false);
  const [messageNotifications, setNotifcations] = useState(0);
  const [createPost, setCreate] = useState(false);
  const [darkMode, setDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
  );
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });
  const userAgent = navigator.userAgent.toLowerCase();
  const isMac = userAgent.includes('mac');
  useEffect(() => {
    if (darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-theme');
    }
  }, [darkMode]);

  useEffect(() => {
    const setThemeBasedOnSystemPreference = () => {
      const isDarkMode =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      setDarkMode(isDarkMode);
    };

    // Set initial theme
    setThemeBasedOnSystemPreference();

    // Add listener for system theme changes
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', setThemeBasedOnSystemPreference);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', setThemeBasedOnSystemPreference);
    };
  }, []);

  useEffect(() => {
    let unopened = 0;
    chain_ids.forEach(chain_id => {
      const {last_sender} = chains?.[chain_id] ?? {};
      if (last_sender !== id) {
        unopened += 1;
      }
    });
    setNotifcations(unopened);
  }, [chain_ids, chains, id]);

  // const handleCopy = () => {
  //   setPopup({
  //     on: true,
  //     message: 'PublicLink copied!',
  //   });

  //   copyString(public_link);
  //   track('public_profile_link_copied', {
  //     link: public_link,
  //     user_id: id,
  //   });
  // };

  const handleHelp = () => {
    showSpace('help');
  };

  const handleMessages = () => {
    navigate('/messages');
  };

  const handleFeed = () => {
    navigate('/feed');
  };

  const handleInvite = () => {
    setAdd(!add);
  };

  const handlePost = () => {
    setCreate(!createPost);
  };

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleHome = () => {
    navigate('/home');
  };

  useEffect(() => {
    const handleKeyDown = event => {
      const {metaKey, ctrlKey, altKey, shiftKey, target, key} = event;

      // Check if the target is an input, textarea, or select element to avoid interfering with typical behavior
      const isInputElement =
        target.tagName === 'INPUT' ||
        target.tagName === 'TEXTAREA' ||
        target.tagName === 'SELECT';

      // Ensure no other modifier keys are pressed
      if ((metaKey || ctrlKey) && !altKey && !shiftKey && !isInputElement) {
        switch (key) {
          case '/':
            event.preventDefault();
            handleHelp();
            track('keyboard_shortcut', {key: '/'});
            break;
          case 'm':
            event.preventDefault();
            handleMessages();
            track('keyboard_shortcut', {key: 'm'});
            break;
          case 's':
            event.preventDefault();
            handleFeed();
            track('keyboard_shortcut', {key: 's'});
            break;
          case 'i':
            event.preventDefault();
            handleInvite();
            track('keyboard_shortcut', {key: 'i'});
            break;
          case 'p':
            event.preventDefault();
            handlePost();
            track('keyboard_shortcut', {key: 'p'});
            break;
          case 'd':
            event.preventDefault();
            handleDarkMode();
            track('keyboard_shortcut', {key: 'd'});
            break;
          case 'h':
            event.preventDefault();
            handleHome();
            track('keyboard_shortcut', {key: 'h'});
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [darkMode, add, createPost]);

  const commandKey = isMac ? 'CMD' : 'CTRL';

  const buttons = [
    {
      onClick: handlePost,
      icon: disabled ? null : (
        <span
          style={{
            display: 'inline-block',
            backgroundColor: '#5367e3',
            padding: '2px 8px', // Increase padding for better visual balance
            borderRadius: '30%',
            color: '#ffffff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow for depth
            transition: 'background-color 0.3s ease, transform 0.3s ease', // Smooth transitions
            cursor: 'pointer', // Change cursor to pointer to indicate clickability
          }}
          onMouseEnter={e =>
            (e.currentTarget.style.backgroundColor = '#4155b2')
          } // Darken background on hover
          onMouseLeave={e =>
            (e.currentTarget.style.backgroundColor = '#5367e3')
          } // Revert background on mouse leave
          onMouseDown={e => (e.currentTarget.style.transform = 'scale(0.95)')} // Slight scale down on click
          onMouseUp={e => (e.currentTarget.style.transform = 'scale(1)')} // Revert scale after click
        >
          <RxLightningBolt size={'18px'} />
        </span>
      ),
      message: 'Create Post',
      commandMessage: `${commandKey} + p`,
      iconTitle: 'Post',
      notifications: 0,
    },
    {
      onClick: handleInvite,
      icon: disabled ? null : <RxPaperPlane size={'18px'} />,
      message: 'Invite Member',
      commandMessage: `${commandKey} + i`,
      iconTitle: 'Invite',
      notifications: 0,
    },
    {
      onClick: handleMessages,
      icon: disabled ? null : <RxEnvelopeClosed size={'18px'} />,
      message: 'Go to Messages',
      commandMessage: `${commandKey} + m`,
      iconTitle: 'Messages',
      notifications: messageNotifications,
    },
    {
      onClick: handleFeed,
      icon: disabled ? null : <RxMagnifyingGlass size={'18px'} />,
      message: 'Search Feed',
      commandMessage: `${commandKey} + s`,
      iconTitle: 'Search',
      notifications: 0,
    },
    // {
    //   onClick: handleCopy,
    //   icon: !disabled && sub ? <RxLink1 size={'20px'} /> : null,
    //   message: 'Copy PublicLink',
    //   commandMessage: `${commandKey} + L`,
    //   iconTitle: 'PublicLink',
    // },
    {
      onClick: handleHelp,
      icon: <RxQuestionMark size={'18px'} />,
      message: 'Help Center',
      commandMessage: `${commandKey} + /`,
      iconTitle: 'Help Center',
      notifications: 0,
    },
    {
      onClick: handleDarkMode,
      icon: darkMode ? <RxMoon size={'18px'} /> : <RxSun size={'18px'} />,
      message: 'Toggle Darkmode',
      commandMessage: `${commandKey} + d`,
      iconTitle: !darkMode ? 'Dark?' : 'Light?',
      notifications: 0,
    },
  ];

  return (
    <>
      {disabled ? null : (
        <>
          <div className="sidebar-toggles">
            {buttons.map((button, i) => {
              const {icon, onClick, message, commandMessage, notifications} =
                button;
              return (
                <div
                  key={i}
                  className="flex align-center justify-center"
                  onClick={onClick}>
                  <TooltipRight text={message} subtext={commandMessage}>
                    {icon}
                    <BadgeDot active={notifications} />
                  </TooltipRight>
                </div>
              );
            })}
          </div>
          <ActionPopup
            message={popup.message}
            setOff={setPopup}
            on={popup.on}
          />
          <InviteGroupModal
            active={add}
            setActive={setAdd}
            group_id={current_group}
          />

          <CreatePost active={createPost} setActive={setCreate} />
        </>
      )}
    </>
  );
};

const FeedHeader = ({}) => {
  const logout = useLogout();
  const {loadOpps, categorizeOpps} = useCollaborator();
  const navigate = useNavigate();
  const {
    state: {
      id,
      profile,
      current_group,
      groups,
      invitations_loaded,
      invitations,
    },
    defaultUpdate,
    getInvitations,
  } = useAuth();

  const {
    state: {new_opps, active_opps, opportunities, opp_token, new_opps_loaded},
    getNewOpportunities,
    defaultUpdate: update,
  } = useWorkspace();
  const {
    state: {new_messages},
    defaultUpdate: updateMessages,
  } = useMessage();
  const {
    state: {chains_loaded},
    getChains,
  } = useMessage();

  const {profile_image, group_ids} = profile || {};
  const {name} = groups?.[current_group] ?? {};
  //REFACTOR LATER
  const defaults = categorizeOpps();
  const incoming =
    defaults.incoming.internal.count + defaults.incoming.external.count;

  const master =
    process.env.NODE_ENV === 'development'
      ? [{text: 'MASTER', onClick: () => navigate('/settings/master')}]
      : [];

  const paths = [
    {
      title: 'Inbox',
      path: '/opportunities',
      icon: <RxCardStack />,
      items: null,
      disabled: false,
      notifications: (new_opps?.length ?? 0) + incoming,
    },
    {
      title: 'Notes',
      path: '/notes',
      icon: <RxFileText />,
      items: null,
      disabled: false,
      notifications: 0,
    },
    {
      title: 'Groups',
      path: '/groups',
      icon: <RxMix />,
      items: null,
      disabled: false,
      notifications: invitations?.length ?? 0,
    },
  ];

  useEffect(() => {
    if (!invitations_loaded) {
      getInvitations({
        filter: {invitee_id: {eq: id}, status: {eq: 'pending'}},
        limit: constants.all_items,
      });
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      const query = {
        filter: {
          status: {eq: 'inactive'},
          members: {contains: id},
          groups: {contains: current_group},
        },
        limit: constants.opportunity_limit,
        nextToken: opp_token,
      };
      if (!new_opps_loaded) {
        getNewOpportunities(query);
        loadOpps();
      }
    };

    load();
  }, [new_opps_loaded]);

  useEffect(() => {
    const fetchChains = async () => {
      const query = {
        type: 'direct',
        sortDirection: 'DESC',
        filter: {members: {contains: id}},
      };
      const {success, error, data} = await getChains(query);
    };

    // FETCH
    if (!chains_loaded) {
      fetchChains();
    }
  }, []);

  return (
    <>
      <div className="feed-header">
        <div className="flex-row align-center justify-center">
          <InvertedTooltip text={'Return Home'}>
            <div
              onClick={() => {
                navigate('/home');
              }}>
              <PublicMindLogoLightNoText />
              <p className="text-secondary text-10">{'Home'}</p>
            </div>
          </InvertedTooltip>
          <div
            style={{
              height: '36px',
              border: '1px solid black',
              margin: '16px',
              boxShadow:
                'rgba(255, 255, 255, 0.2) -1px -1px 0px, rgba(0, 0, 0, 0.1) 1px 1px 0px',
            }}
          />
          <DropDown
            items={group_ids?.map(grp_id => {
              const {name} = groups?.[grp_id] ?? {};
              return {
                text: name,
                onClick: () => {
                  const active = current_group === grp_id;
                  if (!active) {
                    defaultUpdate({
                      current_group: grp_id,
                      profiles_loaded: false,
                      profile_token: null,
                      orgs_loaded: false,
                      org_token: null,
                    });
                    update({
                      posts_loaded: false,
                      post_token: null,
                      feed_loaded: false,
                      feed_token: null,
                      new_opps_loaded: false,
                      opp_token: null,
                      feed: [],
                      post_feed: [],
                      user_posts: {},
                    });
                    track('current_group_updated', {group_id: grp_id});
                  }
                },
              };
            })}>
            <h5>{name}</h5>
            <FaChevronDown size={'18px'} style={{marginLeft: '8px'}} />
          </DropDown>
          <InvertedTooltip text={'Switch groups easily here'}>
            <RxInfoCircled size={'20px'} color="var(--secondary-text)" />
          </InvertedTooltip>
        </div>

        <div className="flex-row align-center">
          {paths.map(section => {
            return <FeedButton key={section.path} section={section} />;
          })}
          <div
            style={{
              height: '36px',
              border: '1px solid black',
              margin: '16px',
              boxShadow:
                'rgba(255, 255, 255, 0.2) -1px -1px 0px, rgba(0, 0, 0, 0.1) 1px 1px 0px',
            }}
          />
          <div className="flex-column">
            <DropDown
              items={[
                {
                  text: 'Profile',
                  onClick: () => navigate('/settings/profile'),
                },
                {
                  text: 'Organization',
                  onClick: () => navigate('/settings/organization'),
                },
                {
                  text: 'Log Out',
                  onClick: logout,
                },
                ...master,
              ]}>
              <div className="margin-8">
                <InvertedTooltip text={'View Profile Settings'}>
                  <ProfileImage
                    data={profile_image}
                    onClick={() => {}}
                    style={{height: '36px', width: '36px'}}
                  />
                </InvertedTooltip>
              </div>
            </DropDown>
          </div>
        </div>
      </div>
      <ActionPopup
        message={'New Direct Message'}
        setOff={() => {
          updateMessages({new_messages: false});
        }}
        on={new_messages}
      />
    </>
  );
};

const FeedButton = ({section}) => {
  const navigate = useNavigate();

  const {title, path, icon, items, disabled, notifications} = section;

  const message =
    path === '/opportunities'
      ? 'Act on Matches'
      : path === '/notes'
      ? 'Your Modern Rolodex'
      : 'View Groups';

  return (
    <InvertedTooltip
      text={disabled ? 'Finish Getting Started to Unlock' : message}>
      <div
        className="flex-column justify-center align-center margin-8 padding-top8 clickable"
        onClick={() => {
          if (disabled) {
            return;
          }
          navigate(path);
        }}>
        <Badge count={notifications} />
        {icon}
        <p className="text-secondary text-10">{title}</p>
      </div>
    </InvertedTooltip>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const mobile = isMobile || isTablet;

  return (
    <div className={mobile ? '' : 'footer-container'}>
      <p className="footer-text text-secondary">
        © 2024 by PublicMind {process.env.REACT_APP_VERSION} <br />
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/terms-of-service');
        }}>
        Terms of Service
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/privacy-policy');
        }}>
        Privacy Policy
      </p>
    </div>
  );
};

export const AuthHeader = ({header, subheader, action}) => {
  const logout = useLogout();

  const mobile = isMobile;

  return (
    <div className="container">
      {action && (
        <LinkButton onClick={logout} className="top-left">
          Log Out
        </LinkButton>
      )}
      <div className="flex-column align-center">
        <div className={mobile ? 'width-80' : 'width-66'}>
          <br />
          <div className="flex-column align-center">
            <PublicMindLogoLightNoText />
          </div>
          {header && <h3 className="">{header}</h3>}
          {subheader && (
            <p className="text-secondary padding-bottom16">{subheader}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export const MobileLayout = ({children}) => {
  const {createReferralLink} = useSearchParameters();
  const logout = useLogout();
  const navigate = useNavigate();

  const {
    state: {id, current_organization, profile, current_group, groups},
    defaultUpdate,
  } = useAuth();
  const {defaultUpdate: update} = useWorkspace();

  const [active, setActive] = useState(false);
  const [share, setShare] = useState(false);
  const [link, setLink] = useState('');

  const {group_ids} = profile || {};
  const {name} = groups?.[current_group] ?? {};

  useEffect(() => {
    const link = createReferralLink();
    setLink(link);
  }, [id, current_organization, current_group]);

  return (
    <>
      <div className="base-container">
        <div
          className="flex-row justify-between align-center"
          style={{marginBottom: '32px'}}>
          <div className="flex-row align-center">
            <button
              className="button-container-mobile"
              onClick={() => {
                navigate('/home');
              }}>
              <FaHouse />
            </button>
            <button
              className="invite-external-button-mobile"
              onClick={() => {
                setShare(!share);
              }}>
              <RxPaperPlane />
            </button>
            <button
              className="button-container-mobile"
              onClick={() => {
                setActive(!active);
              }}>
              <RxLightningBolt />
            </button>
            <DropDown
              items={group_ids?.map(grp_id => {
                const {name} = groups?.[grp_id] ?? {};
                return {
                  text: name,
                  onClick: () => {
                    const active = current_group === grp_id;
                    if (!active) {
                      defaultUpdate({
                        current_group: grp_id,
                        profiles_loaded: false,
                        profile_token: null,
                        orgs_loaded: false,
                        org_token: null,
                      });
                      update({
                        posts_loaded: false,
                        post_token: null,
                        feed_loaded: false,
                        feed_token: null,
                        new_opps_loaded: false,
                        opp_token: null,
                        feed: [],
                        post_feed: [],
                      });
                      track('current_group_updated', {group: grp_id});
                    }
                  },
                };
              })}>
              <h5>{name}</h5>
            </DropDown>
          </div>
          <LinkButton onClick={logout}>
            <MdLogout size={'24px'} />
          </LinkButton>
        </div>
        {children || <Outlet />}
        <Footer />
      </div>
      <MobileCreatePost active={active} setActive={setActive} />
      <Modal active={share} setActive={setShare}>
        <p className="text-bold">Invite Members to the platform</p>
        <br />
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            width: '60%',
          }}>
          <QRCode
            size={256}
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={link}
            viewBox={`0 0 256 256`}
          />
        </div>
        <br />
      </Modal>
    </>
  );
};
