import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  thematicBreakPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import {eachDayOfInterval, subDays} from 'date-fns';
import {useFormik} from 'formik';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FaExternalLinkSquareAlt} from 'react-icons/fa';
import {FaLinkedin, FaSquareGithub, FaSquareXTwitter} from 'react-icons/fa6';
import {GoCrossReference} from 'react-icons/go';
import {IoMdArrowBack} from 'react-icons/io';
import {RiCloseFill} from 'react-icons/ri';
import {
  RxBell,
  RxEnter,
  RxLightningBolt,
  RxPencil1,
  RxPerson,
  RxShare2,
} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {
  awsTimestampToDate,
  dateToTimestamp,
  formatMMDDYYYY,
  isSameDay,
  timeSince,
} from '../../api/dates';
import {MetaEditModal} from '../../components/modal/note-modals';
import {TooltipNoShadow} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import {useNetworking} from '../../hooks/use-networking';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';
import './styles.css';

export const PersonNoteItem = () => {
  const navigate = useNavigate();
  const {loadRolodex} = useLoader();
  const {prettyTag} = useStringFormatter();

  const {
    state: {rolodex_content},
  } = useNetworking();
  const {
    state: {id},
  } = useAuth();

  const {personId} = useParams();

  const [editMeta, setEditMeta] = useState(false);

  const rolodex = rolodex_content?.[personId] ?? {};
  const {
    first_name,
    last_name,
    emails,
    outer_tags,
    inner_tags,
    social_links,
    job_title,
    description,
    company,
  } = rolodex;

  useEffect(() => {
    loadRolodex();
  }, []);
  const getIconForLink = link => {
    if (link.includes('linkedin.com')) {
      return <FaLinkedin size={'30px'} />;
    }
    if (link.includes('twitter.com') || link.includes('x.com')) {
      return <FaSquareXTwitter size={'30px'} />;
    }
    if (link.includes('github.com')) {
      return <FaSquareGithub size={'30px'} />;
    }
    return <FaExternalLinkSquareAlt size={'30px'} />;
  };
  return (
    <div className="note-page-container">
      <div className="flex-row justify-between">
        <IoMdArrowBack
          className="clickable"
          onClick={() => {
            navigate('/notes');
          }}
        />
      </div>
      <div className="grid-container">
        <div className="grid-12 padding-bottom8">
          <div className="flex-row">
            <h4
              className="clickable"
              onClick={() => {
                setEditMeta(true);
              }}>
              {first_name || 'First Name '}
            </h4>
            <h4
              className="clickable padding-left8"
              onClick={() => {
                setEditMeta(true);
              }}>
              {last_name || ' Last Name '}
            </h4>
            <TooltipNoShadow text={'Edit Profile'}>
              <RxPencil1
                onClick={() => {
                  setEditMeta(true);
                }}
                size={'20px'}
                className="clickable"
              />
            </TooltipNoShadow>
            <TooltipNoShadow text={'See Profile'}>
              <RxEnter
                onClick={() => {
                  navigate(`/feed/profiles/detail/${emails?.[0]}`);
                }}
                size={'20px'}
                className="clickable"
              />
            </TooltipNoShadow>
          </div>
          <div className="flex-row">
            <p
              className="text-secondary clickable"
              onClick={() => {
                setEditMeta(true);
              }}>
              {job_title || 'Job Title'}
            </p>
            <p
              className="text-secondary clickable padding-left8"
              onClick={() => {
                setEditMeta(true);
              }}>
              {company || 'Company Name'}
            </p>
          </div>
          <div className="flex-row">
            {emails?.length ? (
              emails.map(email => {
                return (
                  <p key={email} className="text-secondary text-12">
                    {email}
                  </p>
                );
              })
            ) : (
              <p className="text-secondary text-12">xyz@abc.ai</p>
            )}
          </div>
        </div>
        <div className="grid-3">
          <div className="meta-section ">
            <div className="flex-column border-bottom">
              <p
                className="text-left text-secondary text-12 clickable"
                onClick={() => {
                  setEditMeta(true);
                }}>
                Overview
              </p>
              <p
                className="text-14 text-left"
                onClick={() => {
                  setEditMeta(true);
                }}>
                {description || ''}
              </p>
            </div>
            <div className="flex-column border-bottom padding-top8">
              <p
                className="text-left text-secondary text-12 clickable"
                onClick={() => {
                  setEditMeta(true);
                }}>
                Profile Links
              </p>
              <div className="flex-row">
                {social_links &&
                  social_links.map((link, index) => (
                    <a
                      className="flex justify-evenly padding-bottom8"
                      href={link}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer">
                      {getIconForLink(link)}
                    </a>
                  ))}
              </div>
            </div>
            <div className="padding-top8 border-bottom">
              <p
                className="text-left text-secondary text-12 clickable"
                onClick={() => {
                  setEditMeta(true);
                }}>
                Looking For
              </p>

              <div className="flex-row-wrap">
                {outer_tags?.map(tag => {
                  return (
                    <p key={tag} className="bubble-small">
                      {prettyTag(tag)}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="padding-top8 border-bottom">
              <p
                className="text-left text-secondary text-12 clickable"
                onClick={() => {
                  setEditMeta(true);
                }}>
                Available Resources
              </p>

              <div className="flex-row-wrap">
                {inner_tags?.map(tag => {
                  return (
                    <p key={tag} className="bubble-small">
                      {prettyTag(tag)}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <Activity rolodex={rolodex} />
        </div>
        <div className="grid-9">
          <div className="notes-section ">
            <NoteList rolodex={rolodex} />
          </div>
        </div>
      </div>
      {editMeta && (
        <MetaEditModal
          active={editMeta}
          setActive={setEditMeta}
          item={rolodex}
        />
      )}
    </div>
  );
};

const Activity = ({rolodex}) => {
  const navigate = useNavigate();
  const {
    state: {interactions_by_user, interactions_by_other, interactions},
    fetchInteractions,
    fetchOtherInteractions,
  } = useNetworking();
  const {
    state: {id: userId},
  } = useAuth();
  const {
    state: {posts},
    getPost,
  } = useWorkspace();

  const {emails} = rolodex;
  const firstEmail = emails?.[0];
  // TODO: make work for array of emails

  const activity = useMemo(
    () => interactions_by_user?.[firstEmail] ?? [],
    [interactions_by_user, firstEmail],
  );
  const otherActivity = useMemo(
    () => interactions_by_other?.[firstEmail] ?? [],
    [interactions_by_other, firstEmail],
  );

  const fetchPost = async postId => {
    await getPost(postId);
  };

  useEffect(() => {
    // CHECK ACTIVITY FROM CURRENT TO OTHER USER
    if (firstEmail && interactions_by_user[firstEmail] === undefined) {
      fetchInteractions(
        {
          source_user: userId,
          sortDirection: 'DESC',
          filter: {target_user: {eq: firstEmail}},
          limit: 100,
        },
        firstEmail,
      );
    }

    // CHECK ACTIVITY FROM OTHER TO CURRENT USER
    if (firstEmail && interactions_by_other[firstEmail] === undefined) {
      fetchOtherInteractions(
        {
          source_user: firstEmail,
          sortDirection: 'DESC',
          filter: {target_user: {eq: userId}},
          limit: 100,
        },
        firstEmail,
      );
    }
  }, [firstEmail, userId]);

  const renderActivityItem = item => {
    const {id, action, content_type, timestamp, url, content_id} = item;
    const how_long_ago = timeSince(timestamp);
    let icon,
      action_text,
      itemUrl = url;

    switch (action) {
      case 'connect':
        icon = <RxLightningBolt />;
        action_text =
          content_type === 'user'
            ? 'Connected on profile match'
            : content_type === 'post'
            ? 'Connected on post match'
            : 'Connected on match';
        break;
      case 'view':
        icon = <RxEnter />;
        action_text =
          content_type === 'user'
            ? 'Viewed profile'
            : content_type === 'post'
            ? 'Viewed post'
            : 'Viewed match';
        if (content_type === 'post' && !posts?.[content_id]?.title) {
          fetchPost(content_id);
        }
        break;
      case 'refer':
        icon = <GoCrossReference />;
        action_text =
          content_type === 'user'
            ? 'Sent note'
            : content_type === 'post'
            ? 'Sent note'
            : 'Sent match note';
        itemUrl =
          content_type === 'post' ? `/feed/posts/detail/${content_id}` : url;
        break;
      case 'invite':
        icon = <RxPerson />;
        action_text = 'Sent invite';
        break;
      case 'group_invite':
        icon = <RxPerson />;
        action_text = 'Sent group invite';
        break;
      case 'share':
        icon = <RxShare2 />;
        action_text =
          content_type === 'user'
            ? 'Shared profile'
            : content_type === 'post'
            ? 'Shared post'
            : 'Shared match';
        break;
      case 'subscribe':
        icon = <RxBell />;
        action_text = 'Subscribed';
        break;
      case 'ignore':
        icon = <RiCloseFill />;
        action_text =
          content_type === 'user'
            ? 'Ignored profile match'
            : content_type === 'post'
            ? 'Ignored post match'
            : 'Ignored match';
        break;
      default:
        action_text = 'Unknown action';
    }

    return (
      <div
        key={id}
        className={`flex-row align-center justify-between border-bottom ${
          itemUrl ? ' clickable' : ''
        }`}
        onClick={() => itemUrl && navigate(itemUrl)}>
        <p className="text-12 ellipsis-text">
          {icon} {action_text}
        </p>
        <p className="text-secondary text-10">{how_long_ago}</p>
      </div>
    );
  };

  return (
    <>
      <div className="activity-section">
        <p className="text-left text-secondary text-12">Your Activity</p>
        {activity.map(act_id => {
          const item = interactions?.[act_id] ?? {};
          return renderActivityItem(item);
        })}
      </div>
      <div className="activity-section">
        <p className="text-left text-secondary text-12">Their Activity</p>
        {otherActivity.map(act_id => {
          const item = interactions?.[act_id] ?? {};
          return renderActivityItem(item);
        })}
      </div>
    </>
  );
};

const NoteList = ({rolodex}) => {
  const {notes = []} = rolodex || {};

  const [loadedNotes, setLoadedNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(subDays(new Date(), 10));

  const handleScroll = useCallback(() => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight &&
      !loading
    ) {
      setLoading(true);
      setStartDate(prevDate => {
        const newStartDate = subDays(prevDate, 10);
        const newNotes = generateMissingNotes(
          loadedNotes,
          newStartDate,
          prevDate,
        );
        setLoadedNotes(prevNotes => [...prevNotes, ...newNotes]);
        setLoading(false);
        return newStartDate;
      });
    }
  }, [loading, loadedNotes]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const initialNotes = generateMissingNotes(notes, startDate, new Date());
    setLoadedNotes(initialNotes);
  }, []);

  return (
    <div className="note-list">
      {loadedNotes?.map((note, index) => {
        return <NoteListItem key={index} note={note} rolodex={rolodex} />;
      })}
    </div>
  );
};

const generateMissingNotes = (existingNotes, startDate, endDate) => {
  const allDays = eachDayOfInterval({start: startDate, end: endDate});

  const filledNotes = allDays.map(day => {
    const dayTimestamp = Math.floor(day.getTime() / 1000);

    // Check if there is an existing note for the same day
    const existingNote = existingNotes?.find(note =>
      isSameDay(note.created, dayTimestamp),
    );
    if (existingNote) {
      return existingNote;
    } else {
      return {
        created: dayTimestamp,
        updated: dayTimestamp,
        note: '',
      };
    }
  });
  return filledNotes.sort((a, b) => b.created - a.created);
};

const NoteListItem = ({rolodex, note}) => {
  const {updateEntry} = useNetworking();
  const {
    state: {id},
  } = useAuth();

  const created = awsTimestampToDate(note?.created);
  const pretty_created = formatMMDDYYYY(created);

  const handleError = (helpers, err) => {
    helpers.setStatus({success: false});
    helpers.setErrors({submit: err.message});
    helpers.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      note: note?.note ?? '',
      created: note?.created ?? dateToTimestamp(),
    },
    validationSchema: Yup.object({
      note: Yup.string().required(),
      created: Yup.number(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {created, note} = values;
        const newNote = {
          created,
          updated: dateToTimestamp(),
          note: note,
        };

        const update = {
          id: rolodex?.id,
          updated: dateToTimestamp(),
          notes: rolodex?.notes
            ? [
                ...rolodex.notes.filter(n => !isSameDay(n.created, created)),
                newNote,
              ]
            : [newNote],
        };

        const {success, error} = await updateEntry(update);
        if (success) {
          track('rolodex_entry_updated', {user: id, rolodex: update});
        }
        if (error) {
          // console.log('fail!');
          handleError(helpers, error);
        }
      } catch (err) {
        handleError(helpers, err);
      }
    },
  });

  const debouncedSubmit = useDebouncedSubmit(() => {
    formik.submitForm();
  }, 1000);

  const handleEditorChange = useCallback(() => {
    debouncedSubmit();
  }, [debouncedSubmit]);

  const editorRef = useRef(null);

  useEffect(() => {
    if (note) {
      editorRef.current?.setMarkdown(note.note);
      formik.setFieldValue('note', note?.note);
      formik.setFieldValue('created', note?.created);
    }
  }, [note?.note]);

  const handleMarkdownChange = newMarkdown => {
    editorRef.current?.setMarkdown(newMarkdown);
    formik.setFieldValue('note', editorRef.current?.getMarkdown());

    if (handleEditorChange) {
      handleEditorChange();
    }
  };

  const handleClick = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  return (
    <div className="note-editor" onClick={handleClick}>
      <h4>{pretty_created}</h4>

      <MDXEditor
        className="mdx-note"
        ref={editorRef}
        markdown={formik.values.note}
        onChange={handleMarkdownChange}
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          quotePlugin(),
          thematicBreakPlugin(),
          markdownShortcutPlugin(),
          // linkPlugin(),
          // linkDialogPlugin(),
        ]}
      />
    </div>
  );
};

// Utility hook for debouncing a function call
const useDebouncedSubmit = (callback, delay) => {
  const handlerRef = useRef(null);

  const debouncedSubmit = useCallback(() => {
    clearTimeout(handlerRef.current);
    handlerRef.current = setTimeout(() => {
      callback();
    }, delay);
  }, [callback, delay]);

  return debouncedSubmit;
};
