import {post} from 'aws-amplify/api';
import generateConnectionAccepted from '../assets/html/connection-request-accepted';
import generateFilterRequest from '../assets/html/generateFilterRequest';
import generateInvitationNudge from '../assets/html/invitation-nudge';
import generateGroupInvite from '../assets/html/invite-group';
import generateSendNoteEmail from '../assets/html/recommmend-someone';
import generateShareOtherPost from '../assets/html/share-other-post';
import generateSharePost from '../assets/html/share-post';
import generateSharePostFromOpp from '../assets/html/share-post-from-opp';
import generateSharePriorities from '../assets/html/share-priorities';
import {track} from './analytics';

const apiName = 'externalRestAPI';

const sendEmail = async body => {
  try {
    const {to} = body;

    const valid = isValidEmail(to || '');
    if (!valid) {
      return {success: false, error: 'INVALID EMAIL'};
    }

    const path = '/sendEmail';
    const options = {
      headers: {}, // Optional
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body,
    };
    const postOpp = post({apiName, path, options});
    const {body: data, headers, statusCode} = await postOpp.response;
    const json = await data.json();

    return json;
  } catch (err) {
    track('api_error', {type: 'lambda', function: 'sendEmail', err});
    throw err;
  }
};

const isValidEmail = email => {
  if (!email) {
    return false;
  }
  // Regular expression pattern for validating email addresses
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const sendPostToEmail = async (
  member,
  link,
  post,
  profile,
  post_type,
  note,
) => {
  try {
    const {email} = member;
    const {first_name, last_name} = profile || {};
    const pretty_name =
      first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    const htmlContent = generateSharePost({profile, post, link, note});

    const msg = {
      to: email,
      // bcc: constants.contact_email,
      subject: `PublicMind ${post_type} ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      text: `PublicMind ${post_type} ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      html: htmlContent,
    };

    const response = await sendEmail(msg);
    const {success, error} = response || {};

    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendPostFromOppToEmail = async (email, post, pretty_name, post_type) => {
  try {
    const htmlContent = generateSharePostFromOpp({pretty_name, post});

    const msg = {
      to: email,
      subject: `PublicMind Match ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      text: `PublicMind Match ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      html: htmlContent,
    };

    const response = await sendEmail(msg);
    const {success, error} = response || {};

    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendOtherPostToEmail = async (
  member,
  link,
  post,
  profile,
  post_type,
  note,
) => {
  try {
    const {email} = member;
    const {first_name, last_name} = profile || {};
    const pretty_name =
      first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    const htmlContent = generateShareOtherPost({profile, post, link, note});

    const msg = {
      to: email,
      // bcc: constants.contact_email,
      subject: `PublicMind ${post_type} ${
        pretty_name ? 'sent by ' + pretty_name : '/ Invite to PublicMind'
      }`,
      text: `PublicMind ${post_type} ${
        pretty_name ? 'sent by ' + pretty_name : '/ Invite to PublicMind'
      }`,
      html: htmlContent,
    };

    const response = await sendEmail(msg);
    const {success, error} = response || {};

    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendRecommendSomeoneEmail = async (email, post, profile, note) => {
  try {
    const {first_name, last_name} = profile || {};
    const pretty_name =
      first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    const htmlContent = generateSendNoteEmail({profile, post, note});

    const msg = {
      to: email,
      // bcc: constants.contact_email,
      subject: `Note ${
        pretty_name ? 'from ' + pretty_name : 'from PublicMind'
      }`,
      text: `Note ${pretty_name ? 'from ' + pretty_name : 'from PublicMind'}`,
      html: htmlContent,
    };

    const response = await sendEmail(msg);
    const {success, error} = response || {};

    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendPrioritiesToEmail = async (member, link, tags, profile, type) => {
  try {
    const {email} = member;
    const {first_name, last_name} = profile || {};
    const pretty_name =
      first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    const htmlContent = generateSharePriorities({profile, tags, link, type});

    const msg = {
      to: email,
      // bcc: constants.contact_email,
      subject: `PublicMind Priorities ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      text: `PublicMind Priorities ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      html: htmlContent,
    };

    const response = await sendEmail(msg);
    const {success, error} = response || {};

    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendFilterSuggestions = async (filters, org) => {
  try {
    const {id, name} = org;
    const htmlContent = generateFilterRequest({data: filters, org: name});

    const msg = {
      to: 'help@publicmind.ai',
      // bcc: constants.contact_email,
      subject: 'Filter Request',
      text: 'Filter Request',
      html: htmlContent,
    };
    const response = await sendEmail(msg);
    const {success, error} = response || {};
    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendInvitationNudgeEmail = async ({email, profile, note, link}) => {
  try {
    if (!email) {
      return {success: false, error: true};
    }
    const {first_name, last_name} = profile || {};

    const pretty_name =
      first_name || last_name
        ? `${first_name || ''} ${last_name || ''}`
        : email;
    const htmlContent = generateInvitationNudge({profile, note, link});

    const msg = {
      to: email,
      subject: `Invite Reminder from ${pretty_name}`,
      text: `Invite Reminder from ${pretty_name}`,
      html: htmlContent,
    };
    const response = await sendEmail(msg);
    const {success, error} = response || {};
    return {success, error};
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

const sendGroupInvite = async ({email, profile, link, group, note}) => {
  try {
    if (!email) {
      return {success: false, error: true};
    }

    const {name} = group;
    const htmlContent = generateGroupInvite({profile, group, link, note});

    const msg = {
      to: email,
      subject: `Invitation to ${name}`,
      text: `Invitation to ${name}`,
      html: htmlContent,
    };
    const response = await sendEmail(msg);

    const {success, error} = response || {};
    return {success, error};
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

const sendConnectionAccepted = async ({email, profile, org, item, notes}) => {
  try {
    const recipient_html = generateConnectionAccepted({
      profile,
      organization: org,
      opportunity: item,
      notes,
    });
    const recipient_body = {
      to: email,
      subject: 'Connection Accepted',
      text: 'Connection Accepted',
      html: recipient_html,
    };
    await sendEmail(recipient_body);
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

export {
  sendConnectionAccepted,
  sendEmail,
  sendFilterSuggestions,
  sendGroupInvite,
  sendInvitationNudgeEmail,
  sendOtherPostToEmail,
  sendPostFromOppToEmail,
  sendPostToEmail,
  sendPrioritiesToEmail,
  sendRecommendSomeoneEmail,
};
