import React, {useEffect, useState} from 'react';
import {FaBolt} from 'react-icons/fa';
import {track} from '../../api/analytics';
import constants from '../../components/constants';
import {OpportunityItem} from '../../components/item-details/opportunity-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {Tab} from '../../components/tab/tab';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useWorkspace} from '../../hooks/use-workspace';
import CreatePost from '../posts/create-post';
import {OpportunityCard} from './opportunities-components';

const OpportunitiesOverview = () => {
  const {findUpdatedToday, loadOpps, categorizeOpps} = useCollaborator();
  const {
    state: {id, current_group, current_organization},
  } = useAuth();
  const {
    state: {new_opps, active_opps, opportunities, opp_token, new_opps_loaded},
    getNewOpportunities,
  } = useWorkspace();

  const [index, setIndex] = useState(0);
  const [sortedOpps, setSorted] = useState({
    ...constants.default_opp_categories,
  });
  const [create, setCreate] = useState(false);

  const incoming =
    sortedOpps.incoming.internal.count > 0 ||
    sortedOpps.incoming.external.count > 0;

  const tabs = [
    {tab: 'New', index: 0, notifications: new_opps?.length ?? 0},
    {tab: 'Incoming', index: 1, notifications: incoming ? 1 : 0},
    {tab: 'Current', index: 2, notifications: 0},
    {tab: 'Pending', index: 3, notifications: 0},
  ];

  // useEffect(() => {
  //   const load = async () => {
  //     const query = {
  //       filter: {
  //         status: {eq: 'inactive'},
  //         members: {contains: id},
  //         groups: {contains: current_group},
  //       },
  //       limit: constants.opportunity_limit,
  //       nextToken: opp_token,
  //     };
  //     if (!new_opps_loaded) {
  //       getNewOpportunities(query);
  //       loadOpps();
  //     }
  //   };

  //   load();
  // }, [new_opps_loaded]);

  useEffect(() => {
    const defaults = categorizeOpps();
    setSorted(defaults);
  }, [active_opps, opportunities]);

  return (
    <div className="page-container">
      <Tab items={tabs} index={index} setIndex={setIndex} />
      {index === 0 && (
        <>
          {!new_opps_loaded ? (
            <SpinningIndicator />
          ) : new_opps.length > 0 ? (
            <div className="flex-column align-center padding-top16">
              <OpportunityCard item={opportunities[new_opps[0]]} />
            </div>
          ) : (
            <div className="flex-column align-center padding-top16">
              <h5 className="text-bold">No more opportunities</h5>
              <p className="text-secondary">
                Post a Request to find partners instantly
              </p>
              <div className="flex justify-center">
                <button
                  className="button-container"
                  onClick={() => {
                    setCreate(true);
                    track('post_started', {
                      user_id: id,
                      org_id: current_organization,
                    });
                  }}>
                  New Post
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {index === 1 && (
        <div className="grid-container justify-center align-center">
          <div className="card grid-8">
            <div className="flex-row align-center">
              <span className="create-opportunity-item">
                <FaBolt />
              </span>
              <div className="flex-column">
                <h4>Incoming</h4>
                <p className="text-left text-secondary">
                  This is where opportunities/requests others have made appear.
                  Select one to connect or ignore.
                </p>
              </div>
            </div>
            <div className="grid-container padding-top8 justify-center align-center">
              <div className="grid-10">
                <div className="margin-r8">
                  {sortedOpps.incoming.internal.count > 0 ||
                  sortedOpps.incoming.external.count > 0 ? (
                    [
                      ...(sortedOpps.incoming.internal.ids || []),
                      ...(sortedOpps.incoming.external.ids || []),
                    ].map(opp_id => {
                      const opp = opportunities[opp_id];
                      return (
                        <OpportunityItem
                          key={opp_id}
                          item={opp}
                          inFeed={false}
                        />
                      );
                    })
                  ) : (
                    <p className="text-secondary padding-top8">
                      No incoming opportunities
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {index === 2 && (
        <div className="grid-container justify-center align-center">
          <div className="card grid-8">
            <div className="flex-row align-center">
              <span className="create-opportunity-item">
                <FaBolt />
              </span>
              <div className="flex-column">
                <h4>Current</h4>
                <p className="text-left text-secondary">
                  These are your current opportunities where you and another
                  party have agreed to work together. Check their status or
                  select one to see more details.
                </p>
              </div>
            </div>
            <div className="grid-container padding-top8 justify-center align-center">
              <div className="grid-10">
                <div className="margin-r8">
                  {sortedOpps?.active?.connect?.count > 0 ? (
                    sortedOpps?.active?.connect?.ids.map(opp_id => {
                      const opp = opportunities[opp_id];
                      return (
                        <OpportunityItem
                          key={opp_id}
                          item={opp}
                          inFeed={false}
                        />
                      );
                    })
                  ) : (
                    <p className="text-secondary padding-top8">
                      No current opportunities
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {index === 3 && (
        <div className="grid-container justify-center align-center">
          <div className="card grid-8">
            <div className="flex-row align-center">
              <span className="create-opportunity-item">
                <FaBolt />
              </span>
              <div className="flex-column">
                <h4>Pending</h4>
                <p className="text-left text-secondary">
                  This is where opportunities/requests you've made appear. Check
                  their status or select one to see more details.
                </p>
              </div>
            </div>
            <div className="grid-container padding-top8 justify-center align-center">
              <div className="grid-10">
                <div className="margin-r8">
                  {sortedOpps.pending.internal.count > 0 ||
                  sortedOpps.pending.external.count > 0 ? (
                    [
                      ...(sortedOpps.pending.internal.ids || []),
                      ...(sortedOpps.pending.external.ids || []),
                    ].map(opp_id => {
                      const opp = opportunities[opp_id];
                      return <OpportunityItem key={opp_id} item={opp} />;
                    })
                  ) : (
                    <p className="text-secondary padding-top8">
                      No pending opportunities
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CreatePost active={create} setActive={setCreate} />
    </div>
  );
};

export default OpportunitiesOverview;
