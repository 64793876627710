import React, {useEffect, useRef, useState} from 'react';
import {FaDownload} from 'react-icons/fa';
import {IoMdArrowBack} from 'react-icons/io';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {ProfileImage} from '../../components/inputs/uploader';
import {PostItem} from '../../components/item-details/post-item';
import {ProfileItem} from '../../components/item-details/profile-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {
  DisplayField,
  DisplayLink,
  TagDisplay,
} from '../../components/text/display-fields';
import {StatusIcon} from '../../components/widgets/widgets';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import usePdf from '../../hooks/use-pdf';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';

const OrganizationDetail = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {getOrgTags} = useCollaborator();
  const {loadProfiles, loadContacts} = useLoader();

  const {
    state: {organizations, profiles},
    getOrg,
  } = useAuth();
  const {
    state: {org_posts, posts},

    getOrgPosts,
  } = useWorkspace();

  const pdf_ref = useRef();

  const organization = organizations?.[id] ?? {};

  const org_content = org_posts?.[id] ?? [];

  const {members} = organization || {};

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [inner, setInner] = useState([]);
  const [outer, setOuter] = useState([]);

  // FETCH INFO ON RELOAD
  useEffect(() => {
    const fetchOrgs = async () => {
      setLoading(true);
      if (organizations[id] === undefined) {
        await getOrg(id);
      }
      setLoading(false);
    };

    const fetchPosts = async () => {
      setLoading(true);
      const query = {
        status: 'active',
        sortDirection: 'DESC',
        filter: {org_id: {eq: id}},
      };
      await getOrgPosts(query, id);
      setLoading(false);
    };

    if (!org_content.length) {
      fetchPosts();
    }
    fetchOrgs();
  }, []);

  // FETCH MISSING PROFILES
  useEffect(() => {
    const fetchProfs = async () => {
      setLoading(true);
      await loadProfiles(members);
      setLoading(false);
    };
    fetchProfs();
  }, [members]);

  // SORT TAGS
  useEffect(() => {
    const {inner, outer} = getOrgTags(organization);
    setInner(inner);
    setOuter(outer);
  }, [members, profiles]);

  if (!id || !organization?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <div className="page-container" ref={pdf_ref}>
      <IoMdArrowBack
        className="clickable"
        onClick={() => {
          navigate(-1);
        }}
      />
      <Header id={id} loading={loading} active={active} setActive={setActive} />
      <div className="grid-container">
        <div className="grid-7">
          <div className="card">
            <AboutCard id={id} />
          </div>
          <Priorities inner_tags={inner} outer_tags={outer} />
          {!!org_content.length && (
            <div>
              <div className="margin-h16">
                <h4>General Posts</h4>
              </div>
              {org_content.slice(0, 3).map(id => {
                return <PostItem key={id} item={posts[id]} />;
              })}
            </div>
          )}
        </div>

        <div className="grid-5">
          {/* <div className="card">
            <h4>Organization Resources</h4>
            <TagsRadarChart tags={inner} style={{height: '200px'}} />
          </div>
          <div className="card">
            <h4>Organization Requests</h4>
            <TagsRadarChart tags={outer} style={{height: '200px'}} />
          </div> */}
          <Members id={id} />
          <Hierarchy id={id} />
        </div>
      </div>
    </div>
  );
};
const Priorities = ({inner_tags, outer_tags}) => {
  return (
    <div>
      <TagDisplay inner_tags={inner_tags} outer_tags={outer_tags} />
    </div>
  );
};

const Members = ({id}) => {
  const {
    state: {organizations, profiles},
  } = useAuth();

  const [contacts, setContacts] = useState([]);

  const org = organizations?.[id] ?? {};
  const {members} = org;

  return (
    <div className="card">
      <h4>Team</h4>
      {members && members.length > 0 ? (
        members.map(id => {
          return <ProfileItem key={id} item={profiles[id] || {}} />;
        })
      ) : contacts.length > 0 ? (
        contacts.map(contact => {
          const {name, title, email, phone_number, college, url} = contact;
          return (
            <ProfileItem key={name} item={{name, email, position: title}} />
          );
        })
      ) : (
        <div className="flex-column justify-center">
          <p>No contact information.</p>
        </div>
      )}
    </div>
  );
};

const Header = ({id, loading, active, setActive}) => {
  const {downloadPDF} = usePdf();

  const {
    state: {organizations},
  } = useAuth();

  const pdf_ref = useRef();

  const organization = organizations[id];

  const {name, status, profile_image} = organization || {};

  return (
    <div>
      <div className="flex-row align-center justify-between">
        <div className="flex-row align-center">
          {profile_image && (
            <ProfileImage data={profile_image} type="organization" />
          )}
          <h2>{name}</h2>
          <StatusIcon status={status} />
        </div>
        <button
          disabled={loading}
          onClick={() => {
            downloadPDF(pdf_ref);
          }}>
          <FaDownload />
        </button>
      </div>
    </div>
  );
};

const Hierarchy = ({id}) => {
  const {loadOrgs} = useLoader();
  const {
    state: {organizations},
  } = useAuth();

  const org = organizations?.[id] ?? {};
  const {children, parent} = org;

  useEffect(() => {
    if (children?.length) {
      loadOrgs(children);
    }
    if (parent) {
      loadOrgs([parent]);
    }
  }, [children, parent]);

  if (!children?.length && !parent) {
    return null;
  }

  return (
    <div className="card">
      <h4>Related Orgs</h4>
      {parent && <RelatedOrg item={organizations[parent]} />}
      {!!children?.length &&
        children.map(child => {
          return <RelatedOrg key={child} item={organizations[child]} />;
        })}
    </div>
  );
};

const RelatedOrg = ({item}) => {
  const navigate = useNavigate();
  const {id, name, status, members} = item || {};

  return (
    <div
      className="profile-container"
      style={{}}
      onClick={() => {
        navigate(`/feed/organizations/detail/${id}`);
      }}>
      <div className="flex-row align-center">
        <p className="text-left text-bold">{name}</p>
        <StatusIcon status={status} />
      </div>
      <p className="text-left">Members: {members?.length ?? 0}</p>
    </div>
  );
};

const AboutCard = ({id}) => {
  const {capitalize, prettyArray} = useStringFormatter();

  const {
    state: {organizations},
  } = useAuth();

  const organization = organizations?.[id] ?? {};

  const {owner, type, website, address, bio} = organization;

  return (
    <div>
      <h4>About</h4>
      <DisplayField header="Description" content={bio} />
      <DisplayField header="Type" content={capitalize(type)} />
      <DisplayField header="Contact" content={owner} />
      <DisplayLink header="Website" link={website} />
      <DisplayField header="Address" content={address} />
    </div>
  );
};

export default OrganizationDetail;
