import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {track} from '../../api/analytics';
import tags from '../../assets/lists/tags.json';
import {useAuth} from '../../hooks/use-auth';
import {useWorkspace} from '../../hooks/use-workspace';
import {ArraySearchableDropDown, SearchableDropDown} from '../inputs/drop-down';
import {Textfield} from '../inputs/textfields';
import './styles.css';

export const PostFilter = ({setLoading, setShow}) => {
  const {query} = useParams();

  const {
    state: {post_filter},
    searchPosts,
  } = useWorkspace();
  const {
    state: {profile},
  } = useAuth();

  const [editFilter, setFilter] = useState(post_filter);
  const {properties} = profile;

  const search = async (filter, options) => {
    setLoading(true);
    const {success, error, data} = await searchPosts(
      {...filter, properties},
      options,
    );
    setLoading(false);
    track('search', {
      source: 'profile',
      filter: {...filter, properties},
      options,
    });
    return {success, error, data};
  };

  useEffect(() => {
    if (post_filter) {
      if (query) {
        post_filter.description = query;
      }
      setFilter(post_filter);
    }
  }, [post_filter]);

  return (
    <div className="filter-container">
      <h4>Preferences</h4>
      <Textfield
        value={editFilter.description}
        type="text"
        onChange={e => setFilter({...editFilter, description: e.target.value})}
        header="Keyword"
      />
      <SearchableDropDown
        header="Post Type"
        setValue={val => {
          setFilter({...editFilter, type: val});
        }}
        onClear={() => {
          setFilter({...editFilter, type: ''});
        }}
        items={[
          {key: 'Request', value: 'request'},
          {key: 'Resource', value: 'resource'},
          {key: 'Project', value: 'project'},
        ]}
        start={editFilter.type}
      />
      <ArraySearchableDropDown
        header="Find Resources"
        setValues={val => {
          setFilter({...editFilter, inner_tags: val});
        }}
        values={editFilter.inner_tags}
        items={tags}
        mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
      />
      <ArraySearchableDropDown
        header="Find Requests"
        setValues={val => {
          setFilter({...editFilter, outer_tags: val});
        }}
        values={editFilter.outer_tags}
        items={tags}
        mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
      />
      {/* <ArraySearchableDropDown
        header="Opportunities"
        setValues={val => {
          setFilter({...editFilter, opportunities: val});
        }}
        values={editFilter.opportunities}
        items={tags.filter(tag => tag.type === 'opportunity')}
        mappings={{key: 'pretty', value: 'key'}}
      />
      <ArraySearchableDropDown
        header="Skills"
        setValues={val => {
          setFilter({...editFilter, skills: val});
        }}
        values={editFilter.skills}
        items={tags.filter(tag => tag.type === 'skill')}
        mappings={{key: 'pretty', value: 'key'}}
      />
      <ArraySearchableDropDown
        header="Majors"
        setValues={val => {
          setFilter({...editFilter, majors: val});
        }}
        values={editFilter.majors}
        items={tags.filter(tag => tag.type === 'major')}
        mappings={{key: 'pretty', value: 'key'}}
      />
      <ArraySearchableDropDown
        header="Degrees"
        setValues={val => {
          setFilter({...editFilter, degrees: val});
        }}
        values={editFilter.degrees}
        items={tags.filter(tag => tag.type === 'degree')}
        mappings={{key: 'pretty', value: 'key'}}
      /> */}
      <div className="flex-row justify-center">
        <button
          onClick={async () => {
            setLoading(true);
            setShow(false);
            const options = {
              status: 'active',
              sortDirection: 'DESC',
              limit: 10,
            };
            await search(editFilter, options);
            setLoading(false);
          }}>
          Search
        </button>
      </div>
    </div>
  );
};
