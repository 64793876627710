import {getEmailMetadata, listEmails} from '../api/google';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const importEmails = async token => {
    try {
      const limit = 1;
      let i = 0;
      let pageToken = null;
      // let all_messages = [];
      let metadata = [];
      do {
        const response = await listEmails(token, {maxResults: 500, pageToken});

        const {messages, nextPageToken, resultSizeEstimate} = response || {};

        const meta = await Promise.all(
          messages.map(async email => {
            const {id} = email;
            const meta = await safeApiCall(
              async () => await getEmailMetadata(token, id),
            );
            return meta;
          }),
        );

        // all_messages = [...all_messages, ...messages];
        metadata = [...metadata, ...meta];
        pageToken = nextPageToken;
        i += 1;
      } while (pageToken && i < limit);

      return metadata;
    } catch (err) {
      console.log(err);
    }
  };

  const safeApiCall = async (call, retries = 5, delay = 1000) => {
    try {
      return await call();
    } catch (error) {
      if (retries > 0 && isRateLimit(error)) {
        // Wait with exponential backoff + jitter
        const nextDelay = delay * 2 + Math.random() * 100;
        await sleep(nextDelay);
        return safeApiCall(call, retries - 1, nextDelay);
      } else {
        console.log('FAILED AFTER 5');
        return null;
        // throw error;
      }
    }
  };

  const isRateLimit = error => {
    const {response} = error;
    const {status} = response || {};

    if (status === 429) {
      return true;
    }
    return false;
  };

  return {importEmails};
};
