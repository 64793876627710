import {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import {SpinningIndicator} from '../loading/loading-indicator';

const AuthGuard = ({children, redirectTo, reroute, force}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {
    state: {processing, auth, profile, current_group},
  } = useAuth();

  const {status, subscription, expiration} = profile || {};

  // HANDLE ORGANIZATION STATUS
  useEffect(() => {
    // ONLY REROUTE IT ASKED
    if (force || !reroute || processing) {
      return;
    }

    if (status === 'confirmed') {
      navigate('/introduce-yourself');
      return;
    }

    if (!current_group) {
      navigate('/groups');
      return;
    }
  }, [current_group, pathname]);

  if (processing) {
    return (
      <div className="base-container">
        <SpinningIndicator />
      </div>
    );
  }

  // IF AUTHENTICATED AND USER OBJECT
  if (force || auth) {
    return children ? children : <Outlet />;
  }
  // IF NO AUTH
  return <Navigate to={redirectTo || '/signin'} />;
};

export default AuthGuard;
