import {useFormik} from 'formik';
import {useState} from 'react';
import {FaLinkedin, FaSquareGithub, FaSquareXTwitter} from 'react-icons/fa6';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import tagsJSON from '../../assets/lists/tags.json';
import {useAuth} from '../../hooks/use-auth';
import {useNetworking} from '../../hooks/use-networking';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {PublicMindLogoLightNoText} from '../images/logos';
import {FormikArraySearchableDropDown} from '../inputs/drop-down';
import {
  ArrayFormikTextfield,
  FormikTextfield,
  MultilineFormikTextfield,
} from '../inputs/textfields';
import {Modal} from './modal';

export const MetaEditModal = ({active, setActive, item}) => {
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  return (
    <>
      {active ? (
        <MetaEditModalContent
          active={active}
          setActive={setActive}
          item={item}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const MetaEditModalContent = ({active, setActive, item, setPopup}) => {
  const {updateEntry} = useNetworking();
  const {
    state: {id},
  } = useAuth();

  const socialLinkPatterns = {
    linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
    twitter: /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/.*$/,
    github: /^(https?:\/\/)?(www\.)?github\.com\/.*$/,
  };

  const handleError = (helpers, err) => {
    helpers.setStatus({success: false});
    helpers.setErrors({submit: err.message});
    helpers.setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: {
      first_name: item?.first_name ?? '',
      last_name: item?.last_name ?? '',
      job_title: item?.job_title ?? '',
      company: item?.company ?? '',
      description: item?.description ?? '',
      social_links: item?.social_links ?? [],
      inner_tags: item?.inner_tags ?? [],
      outer_tags: item?.outer_tags ?? [],
      tags: item?.tags ?? [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim(),
      last_name: Yup.string().trim(),
      job_title: Yup.string().trim(),
      company: Yup.string().trim(),
      description: Yup.string().trim(),
      social_links: Yup.array()
        .of(
          Yup.string()
            .trim()
            .url('Invalid URL')
            .test('is-valid-social-link', 'Invalid social link', value => {
              if (!value) return true; // Skip empty values
              return (
                socialLinkPatterns.linkedin.test(value) ||
                socialLinkPatterns.twitter.test(value) ||
                socialLinkPatterns.github.test(value)
              );
            }),
        )
        .max(
          3,
          'You can only add one link per platform (LinkedIn, Twitter, GitHub)',
        ),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {
          first_name,
          last_name,
          job_title,
          company,
          description,
          social_links,
          inner_tags,
          outer_tags,
        } = values;
        const update = {
          id: item.id,
          first_name,
          last_name,
          job_title,
          company,
          description,
          social_links,
          inner_tags,
          outer_tags,
          updated: dateToTimestamp(),
        };
        const {success, error} = await updateEntry(update);

        if (success) {
          setActive(false);
          setPopup({on: true, message: 'Success!'});
          track('rolodex_entry_updated', {user: id, rolodex: update});
          formik.resetForm();
        }
        if (error) {
          setPopup({on: true, message: 'Failed to save'});
          handleError(helpers, error);
        }
      } catch (err) {
        setPopup({
          on: true,
          message: 'Error. Please notify the PublicMind team.',
        });

        handleError(helpers, err);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex justify-center padding-bottom8">
        <PublicMindLogoLightNoText />
      </div>
      <div className="update-container flex-column">
        <div className="flex-row alig-center justify-center justify-between padding-top8 padding-h64">
          <FaLinkedin size={'48px'} className="social-button" />
          <FaSquareXTwitter size={'48px'} className="social-button" />
          <FaSquareGithub size={'48px'} className="social-button" />
        </div>
        {/* {social === 'x.com' && (
            <div className=" padding-top8">
              <FormikTextfieldSocialArray
                formik={formik}
                name="social_links"
                type="text"
                socialPlatform={social}
                header="X/Twitter URL"
              />
            </div>
          )}
          {social === 'github.com' && (
            <div className=" padding-top8">
              <FormikTextfieldSocialArray
                formik={formik}
                name="social_links"
                type="text"
                socialPlatform={social}
                header="Github URL"
              />
            </div>
          )}
          {social === 'linkedin.com' && (
            <div className=" padding-top8">
              <FormikTextfieldSocialArray
                formik={formik}
                name="social_links"
                type="text"
                socialPlatform={social}
                header="LinkedIn URL"
              />
            </div>
          )} */}

        <ArrayFormikTextfield
          formik={formik}
          name="social_links"
          type="text"
          header="Social Links"
        />
        <FormikTextfield
          formik={formik}
          name="first_name"
          type="text"
          header="First Name"
        />
        <FormikTextfield
          formik={formik}
          name="last_name"
          type="text"
          header="Last Name"
        />
        <FormikTextfield
          formik={formik}
          name="job_title"
          type="text"
          header="Job Title"
        />
        <FormikTextfield
          formik={formik}
          name="company"
          type="text"
          header="Company"
        />
        <MultilineFormikTextfield
          formik={formik}
          name="description"
          type="text"
          header="Overview"
        />
        <FormikArraySearchableDropDown
          formik={formik}
          header="What are they looking for?"
          name="outer_tags"
          items={tagsJSON}
          mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
        />
        <FormikArraySearchableDropDown
          formik={formik}
          header="What resources do they have?"
          name="inner_tags"
          items={tagsJSON}
          mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
        />
      </div>
      <div className="flex justify-center">
        <button
          disabled={formik.isSubmitting}
          type="button"
          onClick={formik.handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};
