import React, {useEffect, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {useLocation, useNavigate} from 'react-router-dom';
import constants from '../../components/constants';
import {OpportunityItem} from '../../components/item-details/opportunity-item';
import {OrganizationItemPreview} from '../../components/item-details/organization-item';
import {
  EmailField,
  SimpleTagDisplay,
} from '../../components/text/display-fields';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import {useWorkspace} from '../../hooks/use-workspace';

const OpportunitiesList = ({}) => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {loadOpps, categorizeOpps} = useCollaborator();
  const {
    state: {opportunities},
  } = useWorkspace();

  const {type, status} = state || {};

  const [opps, setOpps] = useState({...constants.default_opp_categories});
  const [current, setCurrent] = useState(null);
  const [loading, setLoading] = useState(false);

  const ids = opps.active[type]?.ids ?? [];

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadOpps();
      setLoading(false);
    };
    load();
  }, []);

  useEffect(() => {
    const defaults = categorizeOpps();
    setOpps(defaults);
  }, [opportunities]);

  const prettyType = type => {
    switch (type) {
      case 'connect':
        return 'Active Connections';
      case 'later':
        return 'Saved for Later';
      case 'ignore':
        return 'Ignored Opportunities';
      default:
        return '';
    }
  };

  return (
    <div className="page-container">
      <div className="flex-column">
        <IoMdArrowBack
          className="clickable"
          onClick={() => {
            navigate(-1);
          }}
        />
        <h4>{prettyType(type)}</h4>
      </div>
      <div className="grid-container">
        <div className="grid-7">
          {ids.map(opp_id => {
            return (
              <OpportunityItem
                key={opp_id}
                item={opportunities[opp_id]}
                onHover={() => {
                  setCurrent(opp_id);
                }}
              />
            );
          })}
        </div>

        <CurrentPreview item={opportunities?.[current]} />
      </div>
    </div>
  );
};

const CurrentPreview = ({item}) => {
  const {loadProfiles} = useLoader();
  const {sortOpportunity} = useCollaborator();
  const {getPost} = useWorkspace();
  const {content} = item || {};

  const {other_content} = sortOpportunity(item);

  useEffect(() => {
    if (other_content) {
      const {user_id, content_id, type} = other_content;
      if (type === 'user') {
        loadProfiles([user_id]);
      }
      if (type === 'post') {
        getPost(content_id);
      }
    }
  }, [other_content]);

  if (other_content?.type === 'user') {
    return <UserPreview content={other_content} />;
  }

  if (other_content?.type === 'post') {
    return <PostPreview content={other_content} />;
  }

  return (
    <div className="card">
      <p>No active opportunities.</p>
    </div>
  );
};

const UserPreview = ({content}) => {
  const navigate = useNavigate();
  const {loadOrgs} = useLoader();
  const {user_id, content_id} = content;
  const {
    state: {profiles, organizations},
    searchProfiles,
  } = useAuth();

  const prof = profiles?.[content_id] ?? {};
  const {
    id,
    first_name,
    last_name,
    email,
    inner_tags,
    outer_tags,
    organization_ids,
  } = prof;

  const pretty =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`
      : user_id;

  useEffect(() => {
    loadOrgs(organization_ids);
  }, [organization_ids]);

  return (
    <div className="preview-container">
      <div className="card">
        <h4>{pretty}</h4>
        <div className="border-bottom margin-v4" />
        <EmailField email={email} />
        {!!organization_ids?.length && (
          <div>
            <p className="text-left text-bold">Organizations:</p>
            {organization_ids.map(id => {
              return (
                <OrganizationItemPreview
                  key={id}
                  item={organizations[id] || {}}
                />
              );
            })}
          </div>
        )}
        <SimpleTagDisplay
          inner_tags={inner_tags}
          outer_tags={outer_tags}
          searchable="user"
        />
        {id && (
          <div className="flex-row justify-center">
            <button
              onClick={() => {
                navigate(`/feed/profiles/detail/${id}`);
              }}>
              View Profile
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const PostPreview = ({content}) => {
  const navigate = useNavigate();

  const {loadProfiles} = useLoader();
  const {content_id} = content;
  const {
    state: {posts},
  } = useWorkspace();
  const {
    state: {profiles},
  } = useAuth();

  const post = posts?.[content_id] ?? {};
  const {id, description, inner_tags, outer_tags, user_id} = post;
  const profile = profiles?.[user_id] ?? {};
  const {first_name, last_name, email} = profile;

  useEffect(() => {
    if (user_id) {
      loadProfiles([user_id]);
    }
  }, [user_id]);

  return (
    <div className="preview-container">
      <div className="card">
        <p className="text-left text-bold">{description}</p>
        <div className="border-bottom margin-v4" />
        <SimpleTagDisplay
          inner_tags={inner_tags}
          outer_tags={outer_tags}
          searchable="post"
        />
        <div className="border-bottom margin-v4" />
        <p className="text-left text-bold">
          {first_name} {last_name}
        </p>
        <EmailField email={email} />
        {id && (
          <div className="flex-row justify-center">
            <button
              onClick={() => {
                navigate(`/feed/posts/detail/${id}`);
              }}>
              View Post
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunitiesList;
