import {useFormik} from 'formik';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import constants from '../../components/constants';
import {
  ArrayFormikTextfield,
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {DragAndDropUploader, Uploader} from '../../components/inputs/uploader';
import {useAuth} from '../../hooks/use-auth';

const CreateGroup = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    state: {id: user_id, profile},
    createGroup,
    updateProfile,
  } = useAuth();

  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      id,
      name: '',
      bio: '',
      image: null,
      type: 'public',
      admins: [user_id],
      members: [user_id],
      tags: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      bio: Yup.string().required('About is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {id: group_id} = values;
        const {group_ids} = profile || {};

        // CREATE GROUP
        const now = dateToTimestamp();

        const grp = {
          ...values,
          created: now,
          updated: now,
        };
        const {success, error} = await createGroup(grp);

        if (success) {
          // UPDATE PROFILE
          const groups = [...(group_ids || []), group_id];
          const {success, error} = await updateProfile({
            id: user_id,
            group_ids: groups,
          });

          if (success) {
            formik.resetForm();
            navigate('/groups');
          }
          if (error) {
            helpers.setStatus({success: false});
            helpers.setErrors({submit: error.message});
            helpers.setSubmitting(false);
          }
          track('group_created', {group: grp});
        }

        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error.message});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!id) {
    return <div>Not a valid link.</div>;
  }

  return (
    <div className="card">
      <FormikTextfield formik={formik} name="name" type="text" header="Name" />
      <MultilineFormikTextfield
        rows={5}
        formik={formik}
        name="bio"
        type="text"
        header="About"
      />
      {/* <ArrayFormikTextfield
        formik={formik}
        name="admins"
        type="text"
        header="Admins"
      />
      <ArrayFormikTextfield
        formik={formik}
        name="members"
        type="text"
        header="Members"
      /> */}
      <ArrayFormikTextfield
        formik={formik}
        name="tags"
        type="text"
        header="Tags"
        comma={true}
      />
      <label>Attach an image</label>
      <DragAndDropUploader
        file={file}
        limit={constants.file_20mb}
        setFile={setFile}
        types={[
          ...constants.image_mime_types,
          ...constants.video_mime_types,
          ...constants.pdf_mime_type,
        ]}
      />
      <Uploader
        file={file}
        limit={constants.file_20mb}
        setFile={setFile}
        types={[
          ...constants.image_mime_types,
          ...constants.video_mime_types,
          ...constants.pdf_mime_type,
        ]}
      />
      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center">
        <button
          disabled={formik.isSubmitting}
          onClick={() => {
            if (file) {
              formik.setFieldValue('image', file);
            } else {
              formik.setFieldValue('image', null);
            }
            formik.handleSubmit();
          }}>
          Create
        </button>
      </div>
    </div>
  );
};

export default CreateGroup;
