import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {timestampToTimeOrDate} from '../../api/dates';
import {Textfield} from '../../components/inputs/textfields';
import useLoader from '../../hooks/use-loader';
import {useNetworking} from '../../hooks/use-networking';
import {ConnectEmailComponent} from './connect-email';

import {SpinningIndicator} from '../../components/loading/loading-indicator';
import './styles.css';

const NotesHomepage = ({}) => {
  const navigate = useNavigate();
  const {loadRolodex} = useLoader();
  const {
    state: {rolodex, rolodex_content},
  } = useNetworking();

  const [search, setSearch] = useState('');
  const [sorted, setSorted] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadRolodex();
      setLoading(false);
    };
    load();
  }, []);

  useEffect(() => {
    const filter = () => {
      const filt = rolodex.filter(id => {
        const {first_name, last_name, emails} = rolodex_content?.[id] ?? {};

        if (!search) {
          return true;
        }

        const upper = search.toUpperCase();

        if (
          first_name?.toUpperCase()?.includes(upper) ||
          last_name?.toUpperCase()?.includes(upper)
        ) {
          return true;
        }

        let email_check = false;
        emails.forEach(email => {
          if (email?.toUpperCase()?.includes(upper)) {
            email_check = true;
          }
        });

        return email_check;
      });

      const sorted = filt.sort((a, b) => {
        const a_entry = rolodex_content[a];
        const b_entry = rolodex_content[b];
        return b_entry?.updated - a_entry?.updated;
      });
      setSorted(sorted);
    };

    filter();
  }, [search, rolodex]);

  return (
    <div className="page-container">
      <div className="flex-row justify-between align-center">
        <Textfield
          value={search}
          type="text"
          onChange={e => setSearch(e.target.value)}
          placeholder="Search contacts..."
        />
        <div className="flex-row align-center">
          <button
            onClick={() => {
              navigate('/notes/connect');
            }}>
            Import Contacts
          </button>
          <button
            onClick={() => {
              navigate('/feed', {state: {start_index: 1}});
            }}>
            Search Members
          </button>
        </div>
      </div>
      {loading ? (
        <SpinningIndicator />
      ) : sorted?.length === 0 ? (
        <ConnectEmailComponent />
      ) : (
        <div className="card flex-column">
          {sorted.map(id => {
            const content = rolodex_content?.[id] ?? null;
            return <NoteItem key={id} item={content} />;
          })}
        </div>
      )}
    </div>
  );
};

export const NoteItem = ({item, inProfile = false}) => {
  const navigate = useNavigate();
  const {loadOrgs, loadProfiles} = useLoader();

  const {
    id,
    source_user,
    updated,
    created,
    first_name,
    last_name,
    emails,
    job_title,
    company,
    locations,
    social_links,
    notes,
  } = item || {};

  const pretty_date = timestampToTimeOrDate(updated * 1000);
  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`
      : emails?.[0] ?? null;

  useEffect(() => {
    if (id) {
      loadProfiles([id], {authMode: 'apiKey'});
    }
  }, [id]);

  return (
    <div
      className="note-item"
      onClick={() => {
        navigate(`/notes/${id}`);
      }}>
      <div className="flex-row align-center justify-between">
        <div className="flex-row align-center">
          <p className="text-bold">
            {pretty_name?.split('@')[0] || pretty_name}{' '}
          </p>
          {(company || job_title) && (
            <p className="text-secondary text-12 margin-h8">
              {job_title && company
                ? job_title + ', ' + company
                : job_title
                ? job_title
                : company
                ? company
                : ''}
            </p>
          )}
        </div>
        <div className="flex-row align-center">
          <p className="text-secondary text-12">{pretty_date}</p>
        </div>
      </div>
    </div>
  );
};

export default NotesHomepage;
