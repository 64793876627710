import React, {useEffect, useRef, useState} from 'react';
import {GoImage, GoPerson} from 'react-icons/go';
import {MdOpenInNew} from 'react-icons/md';
import {useAuth} from '../../hooks/use-auth';
import {LinkButton} from '../buttons/buttons';
import {SpinningIndicator} from '../loading/loading-indicator';
import Tooltip from '../tooltip/tooltip';
import './styles.css';

export const Uploader = props => {
  const {header, file, setFile, types, limit, ...rest} = props;

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  let accept = null;

  if (types) {
    accept = types.join(', ');
  }

  return (
    <div>
      {header && <label>{header}</label>}
      <input
        {...rest}
        type="file"
        onChange={handleFileChange}
        accept={accept}
      />
    </div>
  );
};

export const DragAndDropUploader = ({file, setFile, types, limit}) => {
  const [hovered, setHovered] = useState(false);
  const dragCounter = useRef(0);

  const [pretty_types, setTypes] = useState('');

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDragEnter = e => {
    e.preventDefault();
    dragCounter.current++;
    if (dragCounter.current === 1) {
      setHovered(true);
    }
  };

  const handleDragLeave = e => {
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setHovered(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    dragCounter.current = 0; // Reset drag counter on drop
    setHovered(false); // Set hovered to false on drop
    const file = e.dataTransfer.files[0];

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  useEffect(() => {
    if (types?.length ?? false) {
      let pretty = '';
      types.forEach((type, i) => {
        const [temp, ending] = type.split('/');
        pretty += ending;
        if (i !== types.length - 1) {
          pretty += ', ';
        }
      });
      setTypes(pretty);
    }
  }, []);

  return (
    <div>
      <div
        className={`uploader ${hovered ? 'hover' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        {file ? (
          <FileViewer type={file.type} url={URL.createObjectURL(file)} />
        ) : (
          <p>Drag and drop file here</p>
        )}
      </div>
      {pretty_types && (
        <p className="text-14 text-secondary">
          Allowed file type: {pretty_types}
        </p>
      )}
      {limit && (
        <p className="text-14 text-secondary">
          Size limit: {(limit / (1024 * 1024)).toFixed(0)}mb
        </p>
      )}
    </div>
  );
};

export const ProfileImageUpload = props => {
  const {types, limit, type} = props;

  const {
    state: {id, profile, current_organization, organizations},
    updateProfile,
    updateOrganization,
  } = useAuth();

  const organization = organizations?.[current_organization] ?? {};
  const url =
    type === 'organization'
      ? organization.profile_image
      : profile.profile_image;

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  const handleEditClick = () => {
    // Trigger the file input click event
    const inputElement = document.getElementById('image_input');
    if (inputElement) {
      inputElement.click();
    }
  };

  let accept = null;

  if (types) {
    accept = types.join(', ');
  }

  return (
    <div className="flex-column align-center">
      <input
        id="image_input"
        type="file"
        accept={accept || 'image/*'}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
      <ProfileImage
        data={file ? {url: URL.createObjectURL(file)} : url}
        onClick={handleEditClick}
        type={type}
      />
      {loading ? (
        <SpinningIndicator />
      ) : file ? (
        <button
          className="margin-0"
          onClick={async () => {
            setLoading(true);
            if (type === 'organization') {
              await updateOrganization({
                id: current_organization,
                profile_image: file,
              });
            } else {
              await updateProfile({id, profile_image: file});
            }
            setFile(null);
            setLoading(false);
          }}>
          Save
        </button>
      ) : (
        <LinkButton onClick={handleEditClick} className="margin-0 padding-0">
          {url ? 'Edit' : type === 'organization' ? 'Add Logo' : 'Add Image'}
        </LinkButton>
      )}
    </div>
  );
};

export const ProfileImage = ({data, onClick, style, type}) => {
  const src = data?.url ?? null;

  const sizing =
    type === 'organization'
      ? {
          width: '150px',
          maxHeight: '100px',
          borderRadius: '8px',
          coverFit: 'cover',
        }
      : {
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          objectFit: 'cover',
          border: '1px solid black',
        };

  return (
    <div onClick={onClick} className="profile-container-special">
      {src ? (
        <ImageComponent
          data={data}
          styles={{backgroundColor: 'grey', ...sizing, ...style}}
        />
      ) : type === 'organization' ? (
        <GoImage
          style={{
            height: '150px',
            width: '150px',
            ...style,
          }}
        />
      ) : (
        <GoPerson
          style={{
            ...sizing,
            ...style,
          }}
        />
      )}
    </div>
  );
};

export const ProfileImageSimple = ({data, onClick, style, type}) => {
  const src = data?.url ?? null;

  const sizing =
    type === 'organization'
      ? {
          width: '150px',
          maxHeight: '100px',
          borderRadius: '8px',
          coverFit: 'cover',
        }
      : {
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          objectFit: 'cover',
          border: '1px solid black',
        };

  return (
    <div onClick={onClick} className="profile-container-simple">
      {src ? (
        <ImageComponent
          data={data}
          styles={{backgroundColor: 'grey', ...sizing, ...style}}
        />
      ) : type === 'organization' ? (
        <GoImage
          style={{
            height: '150px',
            width: '150px',
            ...style,
          }}
        />
      ) : (
        <GoPerson
          style={{
            ...sizing,
            ...style,
          }}
        />
      )}
    </div>
  );
};

const ImageComponent = ({data, styles}) => {
  const src = data?.url ?? null;
  const [imageStyle, setImageStyle] = useState(styles);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => {
        // Determine style based on image dimensions
        const style =
          img.width > img.height
            ? {
                // More banner-like
                width: '200px', // or '150px' for fixed size
                height: 'auto',
                borderRadius: '8px',
                objectFit: 'cover',
                ...styles,
              }
            : {
                // More circular-like or square
                width: img.width > img.height ? '100px' : '150px',
                height: img.width > img.height ? '100px' : '150px',
                borderRadius: '50%',
                objectFit: 'cover',
                ...styles,
              };
        setImageStyle(style);
      };
      img.src = src;
    }
  }, [src]);

  return src ? <img src={src} alt="" style={imageStyle} /> : null;
};

export const ResizingFileViewer = ({type, url}) => {
  const imgRef = useRef();
  const [imageDimensions, setImageDimensions] = useState({
    width: null,
    height: null,
  });

  useEffect(() => {
    if (type.startsWith('image/')) {
      loadImage();
    }
  }, [type, url]);

  const loadImage = () => {
    const img = new Image();
    img.onload = () => {
      calculateDimensions();
    };
    img.src = url;
    imgRef.current.src = url;
  };

  const calculateDimensions = () => {
    const parentWidth = imgRef.current.parentElement.offsetWidth;
    const parentStyles = window.getComputedStyle(imgRef.current.parentElement);
    const parentPaddingLeft = parseFloat(parentStyles.paddingLeft);
    const parentPaddingRight = parseFloat(parentStyles.paddingRight);
    const parentMarginLeft = parseFloat(parentStyles.marginLeft);
    const parentMarginRight = parseFloat(parentStyles.marginRight);
    const totalParentWidth =
      parentWidth -
      parentPaddingLeft -
      parentPaddingRight -
      parentMarginLeft -
      parentMarginRight;

    const maxWidth = Math.min(totalParentWidth, 800); // Maximum width of 800 pixels
    const {naturalWidth, naturalHeight} = imgRef.current;
    const aspectRatio = naturalWidth / naturalHeight;

    let width = maxWidth;
    let height = maxWidth / aspectRatio;

    setImageDimensions({width, height});
  };

  switch (type) {
    case 'url':
      return (
        <div className="flex">
          <Tooltip text={`Open the content in a new tab`}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <button>
                <MdOpenInNew />
              </button>
            </a>
          </Tooltip>
        </div>
      );
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/svg+xml':
      return (
        <img
          ref={imgRef}
          alt="Content"
          src={url}
          width={imageDimensions.width}
          height={imageDimensions.height}
        />
      );
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/mpeg':
    case 'video/quicktime':
      return (
        <video controls style={{width: '100%'}}>
          <source src={url} type={type} />
          Your browser does not support the video tag.
        </video>
      );
    case 'application/pdf':
      return (
        <iframe src={url} width="100%" height="500px" title="Content PDF" />
      );
    default:
      return null;
  }
};

export const FileViewer = ({type, url}) => {
  switch (type) {
    case 'url':
      return (
        <div className="flex">
          <Tooltip text={`Open the content in a new tab`}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <button>
                <MdOpenInNew />
              </button>
            </a>
          </Tooltip>
        </div>
      );
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/svg+xml':
      return <img alt="Content" src={url} width={'100%'} />;
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/mpeg':
    case 'video/quicktime':
      return (
        <video controls style={{width: '100%'}}>
          <source src={url} type={type} />
          Your browser does not support the video tag.
        </video>
      );
    case 'application/pdf':
      return (
        <iframe src={url} width="100%" height="500px" title="Content PDF" />
      );
    default:
      return null;
  }
};
