import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {track} from '../../api/analytics';
import constants from '../../components/constants';
import {GroupFilter} from '../../components/filters/group-filter';
import {OrganizationFilter} from '../../components/filters/organization-filter';
import {PostFilter} from '../../components/filters/post-filter';
import {ProfileFilter} from '../../components/filters/profile-filter';
import {GroupItem} from '../../components/item-details/group-item';
import {DetailedOrganizationItem} from '../../components/item-details/organization-item';
import {PostItem} from '../../components/item-details/post-item';
import {DetailedProfileItem} from '../../components/item-details/profile-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {Tab} from '../../components/tab/tab';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import {useWorkspace} from '../../hooks/use-workspace';

const Search = ({}) => {
  const {state} = useLocation();

  const {start_index} = state || {};

  const [index, setIndex] = useState(start_index || 0);
  const [loading, setLoading] = useState(false);

  const tabs = [
    {tab: 'Posts', index: 0},
    {tab: 'People', index: 1},
    {tab: 'Groups', index: 2},
    // {tab: 'Organizations', index: 3},
  ];

  return (
    <div className="page-container">
      <Tab items={tabs} index={index} setIndex={setIndex} />

      {index === 0 && <PostSearch loading={loading} setLoading={setLoading} />}
      {index === 1 && (
        <ProfileSearch loading={loading} setLoading={setLoading} />
      )}
      {index === 2 && <GroupSearch loading={loading} setLoading={setLoading} />}
      {/* {index === 3 && (
        <OrganizationSearch loading={loading} setLoading={setLoading} />
      )} */}
    </div>
  );
};

const PostSearch = ({setLoading, loading}) => {
  const {
    state: {post_search, post_filter, post_token, posts, posts_loaded},
    searchPosts,
  } = useWorkspace();
  const {
    state: {profile, groups, current_group},
  } = useAuth();

  const search = async (filter, options) => {
    setLoading(true);
    const filt = {...filter, group_ids: [current_group]};
    const {success, error, data} = await searchPosts(filt, options);
    setLoading(false);
    track('search', {source: 'posts', filter: filt, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!posts_loaded) {
      search(post_filter, {
        status: 'active',
        sortDirection: 'DESC',
        limit: 10,
        nextToken: post_token,
      });
    }
  }, [posts_loaded]);

  return (
    <div className="grid-container">
      <div className="grid-8">
        <div className="card-light">
          {!!post_search.length ? (
            post_search.map(id => {
              return <PostItem key={id} item={posts[id]} />;
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No posts matched your search. Try reducing the number of filters
              if you're using several.
            </p>
          )}
          {loading && <SpinningIndicator />}
          {!loading && post_token && !!post_search.length ? (
            <div className="flex justify-center">
              <button
                onClick={async () => {
                  const options = {
                    status: 'active',
                    sortDirection: 'DESC',
                    limit: 10,
                    nextToken: post_token,
                  };
                  const {success, error, data} = await search(
                    post_filter,
                    options,
                  );
                }}>
                SEE MORE
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <PostFilter setLoading={setLoading} setShow={() => {}} />
    </div>
  );
};

const ProfileSearch = ({loading, setLoading}) => {
  const {loadRolodex} = useLoader();

  const {
    state: {
      profiles,
      profile_search,
      profile_filter,
      profile_token,
      current_group,
      profiles_loaded,
    },
    searchProfiles,
  } = useAuth();

  const search = async (filter, options) => {
    setLoading(true);
    const filt = {...filter, group_ids: current_group};
    const {success, error, data} = await searchProfiles(filt, options);
    setLoading(false);
    track('search', {source: 'profile', filter: filt, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!profiles_loaded) {
      search(profile_filter, {
        limit: constants.org_search_limit,
        nextToken: profile_token,
      });
    }
    loadRolodex();
  }, [profiles_loaded]);

  return (
    <div className="grid-container">
      <div className="grid-8">
        <div className="card-light">
          {!!profile_search.length ? (
            profile_search.map(id => {
              return <DetailedProfileItem key={id} item={profiles[id]} />;
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No person matched your search. Try reducing the number of filters
              if you're using several.
            </p>
          )}
          {loading && <SpinningIndicator />}
          {!loading && profile_token && !!profile_search.length ? (
            <div className="flex justify-center">
              <button
                onClick={async () => {
                  const options = {
                    limit: constants.org_search_limit,
                    nextToken: profile_token,
                  };
                  const {success, error, data} = await search(
                    profile_filter,
                    options,
                  );
                }}>
                SEE MORE
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ProfileFilter setLoading={setLoading} setShow={() => {}} />
    </div>
  );
};

const OrganizationSearch = ({}) => {
  const {
    state: {organizations, org_search, org_filter, orgs_loaded, org_token},
    searchOrgs,
  } = useAuth();

  const [editFilter, setFilter] = useState(org_filter);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const end = page * constants.org_search_limit;

  useEffect(() => {
    setFilter(org_filter);
  }, [org_filter]);

  const search = async (filter, options, paginate) => {
    setLoading(true);
    const {success, error, data} = await searchOrgs(filter, options, paginate);
    setLoading(false);
    track('search', {source: 'organization', filter, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!orgs_loaded) {
      search(editFilter, {
        limit: constants.org_search_limit,
        nexToken: org_token,
      });
    }
  }, []);

  return (
    <div className="grid-container">
      <div className="grid-8">
        <div className="card-light">
          {org_search.length ? (
            org_search.slice(0, end).map(id => {
              return (
                <DetailedOrganizationItem key={id} item={organizations[id]} />
              );
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No organizations matched your search. Try reducing the number of
              filters if you're using several.
            </p>
          )}
          {loading && <SpinningIndicator />}
          {!loading &&
          org_token &&
          org_search.length &&
          org_search.length % constants.org_search_limit === 0 ? (
            <div className="flex justify-center">
              <button
                onClick={async () => {
                  setPage(page + 1);
                  const options = {
                    limit: constants.org_search_limit,
                    nextToken: org_token,
                  };
                  const {success, error, data} = await search(
                    org_filter,
                    options,
                    true,
                  );
                }}>
                SEE MORE
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <OrganizationFilter setLoading={setLoading} setShow={() => {}} />
    </div>
  );
};

const GroupSearch = ({}) => {
  const {
    state: {group_search, groups, group_token, group_filter, groups_loaded},
    searchGroups,
  } = useAuth();

  const [loading, setLoading] = useState(false);

  const search = async (filter, options) => {
    setLoading(true);
    const {success, error, data} = await searchGroups(filter, options);
    setLoading(false);
    track('search', {source: 'groups', filter, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!groups_loaded) {
      search(group_filter, {
        limit: constants.org_search_limit,
        nextToken: group_token,
      });
    }
  }, [groups_loaded]);

  return (
    <div className="grid-container">
      <div className="grid-8">
        <div className="card-light">
          {group_search.length ? (
            group_search.map(id => {
              const {name, bio} = groups?.[id] ?? {};
              return <GroupItem key={id} item={groups?.[id] ?? {}} />;
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No groups matched your search. Try reducing the number of filters
              if you're using several.
            </p>
          )}
          {loading && <SpinningIndicator />}
          {!loading &&
          group_token &&
          group_search.length &&
          group_search.length % constants.org_search_limit === 0 ? (
            <div className="flex justify-center">
              <button
                onClick={async () => {
                  const options = {
                    limit: constants.org_search_limit,
                    nextToken: group_token,
                  };
                  const {success, error, data} = await search(
                    group_filter,
                    options,
                  );
                }}>
                SEE MORE
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <GroupFilter setLoading={setLoading} />
    </div>
  );
};

export default Search;
