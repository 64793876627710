import React, {useEffect, useState} from 'react';
import {FaBolt} from 'react-icons/fa6';
import {useNavigate, useParams} from 'react-router-dom';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {InteractiveOpportunityItem} from '../../components/item-details/opportunity-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';

import {dateToTimestamp} from '../../api/dates';
import constants from '../../components/constants';
import EmailPreviewModal from '../../components/email/email-preview';
import {FindingMatchesGraph} from '../../components/loading/network-indicator';
import {SharePostComponent} from '../../components/modal/external-engagement-modals';
import {useAuth} from '../../hooks/use-auth';
import './styles.css';

const SharePost = ({}) => {
  const navigate = useNavigate();
  const {id} = useParams();

  const {
    state: {id: user_id, profile, current_group, groups},
    updateProfile,
  } = useAuth();
  const {
    state: {posts, content_opps, content_opps_loading, time},
    getPost,
    getOpportunitiesByContent,
  } = useWorkspace();

  const post = posts?.[id] ?? {};

  const opps = content_opps?.[id] ?? [];
  const {subscription, expiration} = profile ?? {};
  const {type, admins} = groups?.[current_group] ?? {};

  const invitable = type === 'public' ? true : admins?.includes(user_id);

  const [loading, setLoading] = useState(false);

  const [popup, setPopup] = useState({on: false, message: ''});

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getPost(id);
      setLoading(false);
    };

    const loadOpps = async () => {
      const query = {
        filter: {content_ids: {contains: id}, status: {ne: 'closed'}},
        limit: constants.all_items,
      };

      await getOpportunitiesByContent(query, id);
    };

    if (posts[id] === undefined) {
      load();
    }

    if (!content_opps_loading && content_opps[id] === undefined) {
      loadOpps();
    }
  }, []);

  return (
    <div className="page-container">
      <span className="margin-h16" />
      {loading || content_opps_loading ? (
        <FindingMatchesGraph />
      ) : (
        <div>
          <div className="grid-container justify-center">
            <div className="grid-8">
              <div className="card magic-moment">
                <p className="text-left text-12 post-live-text">
                  Your post is live!
                </p>
                <p className="text-left matches-info">
                  <span className="match-number">
                    {opps?.length ?? 0}{' '}
                    {opps?.length === 1 ? 'match' : 'matches'}
                  </span>{' '}
                </p>
                <p className="text-left matches-info">
                  <span className="success-text">{time} seconds</span>
                </p>
              </div>
              <div className="card">
                <MatchesContainer opps={opps} post={post} />
              </div>
              {(!subscription || !expiration) && (
                <div className="flex justify-center">
                  <button
                    onClick={async () => {
                      // IF THEY CANT INVITE ANYONE LET THEM IN NOW
                      if (!invitable) {
                        await updateProfile({
                          id,
                          subscription: 'monthly_vouches',
                          expiration: dateToTimestamp() + constants.days_30,
                        });
                      }
                      navigate('/home');
                    }}>
                    Continue
                  </button>
                </div>
              )}
            </div>
            <div className="grid-4">
              <div className="card">
                <SharePostComponent post={post} />
              </div>
            </div>
          </div>
        </div>
      )}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

const MatchesContainer = ({opps, post}) => {
  const {capitalize} = useStringFormatter();

  const {
    state: {profile},
  } = useAuth();
  const {
    state: {content_opps_loading, opportunities},
  } = useWorkspace();

  const [sorted, setSorted] = useState(opps);

  const post_type = capitalize(post?.type ?? 'Post');

  const [popup, setPopup] = useState({on: false, message: ''});

  useEffect(() => {
    const counted = opps.map(opp_id => {
      const {tags} = opportunities?.[opp_id] ?? {};

      let count = 0;
      tags?.forEach(tag => {
        if (tag.includes('serv_') || tag.includes('opp_')) {
          count += 2;
        } else {
          count += 1;
        }
      });

      return {opp_id, count};
    });

    const sorted = counted.sort((a, b) => {
      return b?.count - a?.count;
    });
    setSorted(sorted);
  }, [opps, opportunities]);

  return (
    <>
      <div className="flex-column align-center padding-bottom16 margin-h16">
        <span className="create-opportunity-item">
          <FaBolt />
        </span>
        <h2 className="invite-modal-title">PublicMind Matches</h2>

        <p className="invite-modal-description">
          {content_opps_loading
            ? 'Searching for potential partners...'
            : sorted?.length === 0
            ? ''
            : `These PublicMind members could be ideal partners for your ${post_type}. Click on any below to review or share your post via email`}
        </p>
      </div>
      <div className="grid-container justify-center">
        {content_opps_loading && <SpinningIndicator />}
        {sorted?.slice(0, 3).map((opp, index) => {
          const {opp_id} = opp;

          return (
            <div key={index} className="grid-10 invite-modal-form-group">
              <InteractiveOpportunityItem opp_id={opp_id} />
            </div>
          );
        })}
      </div>

      <div className="flex justify-center invite-modal-actions">
        {sorted?.length > 0 ? null : (
          <p className="text-secondary">
            {!content_opps_loading ? 'No recommended partners' : ''}
          </p>
        )}
      </div>
      {sorted?.length > 0 && post ? (
        <EmailPreviewModal
          emailType={'sharePostFromOpp'}
          emailProps={{
            email: '',
            post,
            pretty_name:
              profile.first_name || profile.last_name
                ? `${profile.first_name || ''} ${profile.last_name || ''}`
                : profile.id,
            post_type,
          }}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export default SharePost;
