import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {FaCheck, FaChevronDown, FaChevronUp} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import {dateToTimestamp, formatMMDDYYYY} from '../../api/dates';
import constants from '../../components/constants';
import {OpportunityItem} from '../../components/item-details/opportunity-item';
import {PostItem} from '../../components/item-details/post-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {InviteGroupModal} from '../../components/modal/invite-group-modal';
import {ReferralBox} from '../../components/referral/referral';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';
import CreatePost from '../posts/create-post';
import './styles.css';

const Home = () => {
  const {update} = useIntercom();

  const {
    state: {profile},
  } = useAuth();

  const active = profile?.subscription && profile?.expiration;

  useEffect(() => {
    update({
      customAttributes: {
        onboarded: active ? true : false,
      },
    });
  }, [profile]);

  return (
    <div className="page-container">{active ? <Feed /> : <InviteMember />}</div>
  );
};

const InviteMember = ({}) => {
  const {capitalize} = useStringFormatter();
  const {
    state: {id, referrals, current_group, groups},
    getReferrals,
    updateProfile,
  } = useAuth();

  const {type, admins} = groups?.[current_group] ?? {};
  const invitable = type === 'public' ? true : admins?.includes(id);

  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const sorted = [...referrals].sort((a, b) => {
    return b?.timestamp - a?.timestamp;
  });

  useEffect(() => {
    // IF THEY CANT INVITE ANYONE LET THEM IN THE APP
    if (!invitable) {
      updateProfile({
        id,
        subscription: 'monthly_vouches',
        expiration: dateToTimestamp() + constants.days_30,
      });
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getReferrals({
        filter: {referral_user: {eq: id}},
        limit: constants.all_items,
      });
      setLoading(false);
    };
    if (referrals?.length === 0) {
      load();
    }
  }, []);

  return (
    <div className="card">
      <h4 className="text-bold">
        Invite at least one person to enter PublicMind
      </h4>
      <span className="border-bottom padding-top8" />

      <p className="text-left text-secondary padding-bottom16">
        {referrals?.length >= 3
          ? 'You are welcome to vouch for more people or go ahead and press Continue below'
          : `Vouch for ${
              3 - referrals?.length
            } more people to get a month of no-cost access`}
      </p>

      {loading && <SpinningIndicator />}

      {sorted.map(referral => {
        const {id, timestamp, status} = referral;

        return (
          <div className="flex-row justify-between align-center" key={id}>
            <p className="text-bold">{id}</p>
            <p>{capitalize(status.replace('_', ' '))}</p>
          </div>
        );
      })}
      <br />

      <div className="flex-row justify-between align-center">
        {invitable ? (
          <button
            type="button"
            className="invite-external-button"
            onClick={() => {
              setAdd(!add);
            }}>
            INVITE
          </button>
        ) : (
          <div />
        )}

        <button
          disabled={referrals.length < 1}
          type="button"
          className="button-container"
          onClick={async () => {
            await updateProfile({
              id,
              subscription: 'monthly_vouches',
              expiration: dateToTimestamp() + constants.days_30,
            });
          }}>
          CONTINUE
        </button>
      </div>
      <InviteGroupModal
        active={add}
        setActive={setAdd}
        group_id={current_group}
      />
    </div>
  );
};

const GettingStarted = ({}) => {
  const current_date_pretty = formatMMDDYYYY();

  const mobile = isMobile || isTablet;

  return (
    <div>
      <div className="flex-row">
        <div>
          <p className="text-left text-secondary margin-h16">
            {current_date_pretty}
          </p>
          <h2>Getting Started</h2>
          <p className="text-left text-secondary margin-h16">
            Ready to Network at the Speed of Light? You can skip steps 1 & 2 if
            you're ready to go.
          </p>
        </div>
      </div>
      <div
        className={`getting-started-card ${
          mobile ? 'padding-8' : 'padding-32'
        }`}>
        <CreateProfile />
        <div className="border-bottom margin-v16" />
        <JoinOrg />
        <div className="border-bottom margin-v16" />
        {/* <FirstPost />
        <div className="border-bottom margin-v16" /> */}
        <FinalizeAccount />
      </div>
    </div>
  );
};

const CreateProfile = ({}) => {
  const navigate = useNavigate();

  const {
    state: {profile},
  } = useAuth();

  const [show, setShow] = useState(false);

  const complete = profile?.status === 'complete';
  const mobile = isMobile || isTablet;

  return (
    <div>
      <Header
        complete={complete}
        header="1. Optimize Your Matches ⚡️ (Optional)"
        setShow={setShow}
        show={show}
      />
      {show && (
        <div className="card flex-column align-center">
          <br />
          <h5>
            {!complete
              ? 'Tags Drive Matches'
              : 'You’ve taken the first step towards powerful connections. Good work!'}
          </h5>
          <p className={`text-left ${mobile ? '' : 'padding-h64'}`}>
            {!complete
              ? 'Tags on your profile represent your current priorities. Take the first step towards powerful connections by adding your current priorities!'
              : ''}
          </p>
          {!complete && (
            <p className="text-secondary">
              Your tags help us decide what opportunities to send you
            </p>
          )}

          <button
            className="button-container"
            onClick={() => {
              // navigate('/settings/profile');
              navigate('/profile-tag-resources');
            }}>
            {!complete ? 'Start' : 'Redo'}
          </button>
        </div>
      )}
    </div>
  );
};

const JoinOrg = ({}) => {
  const navigate = useNavigate();
  const {
    state: {current_organization},
  } = useAuth();

  const [show, setShow] = useState(false);

  const complete = !!current_organization;
  const mobile = isMobile || isTablet;

  return (
    <div>
      <Header
        complete={complete}
        header="2. Highlight Your Organization 💻 (Optional)"
        setShow={setShow}
        show={show}
      />
      {show && (
        <div className="card flex-column align-center">
          <br />
          <h5>
            {!complete
              ? 'Highlight Your Workplace to Drive Opportunities'
              : 'Well done - this will drive better opportunities for you.'}
          </h5>
          <p className={`text-left ${mobile ? '' : 'padding-h64'}`}>
            {!complete
              ? 'Your workplace sets the stage for meaningful collaborations. Help others understand your professional environment and invite your colleagues to amplify your potential.'
              : ''}
          </p>
          {!complete && (
            <p className="text-secondary">
              Your organization helps the community better understand your needs
            </p>
          )}

          <button
            className="button-container"
            onClick={() => {
              navigate('/find-organization');
            }}>
            {!complete ? 'Start' : 'Redo'}
          </button>
        </div>
      )}
    </div>
  );
};

const FirstPost = ({}) => {
  const {
    state: {id, profile},
  } = useAuth();

  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);

  const complete = !!profile?.post_created;
  const mobile = isMobile || isTablet;

  return (
    <div>
      <Header
        complete={complete}
        header="3. What are you currently working on?"
        setShow={setShow}
        show={show}
      />
      {show && (
        <div className="card flex-column align-center">
          <br />
          <h5>
            {!complete
              ? 'PublicMind connects you with other professionals to solve problems.'
              : 'Lookout for matches to connect with other members once you enter PublicMind below.'}
          </h5>
          <p className={` ${mobile ? '' : 'padding-h64'}`}>
            {!complete
              ? 'Post something you are actively working on to get matched with members who can help.'
              : ''}
          </p>
          {!complete && (
            <p className="text-secondary">
              The best way to get help is to ask for it
            </p>
          )}
          {!complete && (
            <button
              className="button-container"
              onClick={() => {
                setActive(!active);
              }}>
              Start
            </button>
          )}
        </div>
      )}
      <CreatePost active={active} setActive={setActive} />
    </div>
  );
};

const FinalizeAccount = ({}) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const mobile = isMobile || isTablet;

  return (
    <div>
      <Header
        complete={false}
        header="3. Complete Sign Up"
        setShow={setShow}
        show={show}
      />
      {show && (
        <div className="card flex-column align-center">
          <br />
          <h5>Select Your Plan</h5>
          {/* <p className={` ${mobile ? '' : 'padding-h64'}`}>
            Select from Growth (Free) or Paid memberships to complete your
            setup.
          </p> */}

          <button
            className="button-container"
            onClick={() => {
              navigate('/confirm-subscription');
            }}>
            Start
          </button>

          <p className="text-secondary text-14">
            The Growth Membership is free, for now!
          </p>
        </div>
      )}
    </div>
  );
};

const Header = ({complete, header, setShow, show}) => {
  return (
    <div
      className="flex-row justify-between align-center"
      onClick={() => {
        setShow(!show);
      }}>
      <div className="flex-row align-center">
        <CompleteBubble complete={complete} />
        <h5 className={complete ? 'text-through text-secondary' : ''}>
          {header}
        </h5>
      </div>
      <div className="flex-row align-center">
        <CompleteBadge complete={complete} />
        {show ? <FaChevronUp fontSize={16} /> : <FaChevronDown fontSize={16} />}
      </div>
    </div>
  );
};

const CompleteBubble = ({complete}) => {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px',
        border: `3px solid ${complete ? 'transparent' : '#00c9a7'}`,
      }}>
      {complete && <FaCheck fontSize={12} />}
    </div>
  );
};

const CompleteBadge = ({complete}) => {
  const mobile = isMobile || isTablet;

  if (!complete || mobile) {
    return null;
  }

  return (
    <div
      style={{
        marginRight: '8px',
        padding: '4px 8px',
        borderRadius: '8px',
      }}>
      <p className="text-bold">Complete</p>
    </div>
  );
};

const Feed = ({}) => {
  const navigate = useNavigate();
  const {
    state: {id, current_group},
  } = useAuth();
  const {
    state: {feed, opportunities, feed_loaded, feed_token, posts, post_feed},
    getOpportunityFeed,
  } = useWorkspace();

  const mobile = isMobile || isTablet;

  const [loading, setLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const [page, setPage] = useState(30);

  const load = async () => {
    setLoading(true);
    const query = {
      status: 'inactive',
      sortDirection: 'DESC',
      filter: {members: {contains: id}, groups: {contains: current_group}},
      limit: constants.opportunity_limit,
      nextToken: feed_token,
    };
    await getOpportunityFeed(query);
    setLoading(false);
  };

  useEffect(() => {
    if (!feed_loaded) {
      load();
    }
  }, [feed_loaded]);

  // HANDLE PAGINATION
  useEffect(() => {
    if (feed_loaded && feed_token && page > feed.length) {
      load();
    }
  }, [page]);

  return (
    <div className={`${mobile ? 'margin-h8' : ''}`}>
      <div className={`grid-container`}>
        <div className="grid-8">
          {feed.length ? (
            feed.slice(0, page).map((opp_id, i) => {
              const index = indexToPost(i, feed?.length ?? 0);
              const post_id = post_feed[index] ?? null;
              const post = posts?.[post_id] ?? null;

              return (
                <div key={opp_id}>
                  <OpportunityItem
                    item={opportunities[opp_id]}
                    key={opp_id}
                    inFeed={true}
                  />
                  {post && <PostItem item={post} />}
                </div>
              );
            })
          ) : loading ? null : (
            <div className="card">
              <div className="flex-row justify-center">
                <button
                  className="create-opportunity-button"
                  onClick={() => {
                    setCreate(!create);
                  }}>
                  Create Post
                </button>
                <button
                  className="profile-button"
                  onClick={() => {
                    navigate('/settings/profile');
                  }}>
                  Edit Profile
                </button>
              </div>
              <p className="text-secondary">
                You have no incoming opportunities. Update your profile tags or
                create a post to receive more.
              </p>
            </div>
          )}
          {loading && <SpinningIndicator />}

          {feed_token && (
            <div className="flex justify-center">
              <button
                onClick={() => {
                  if (mobile) {
                    setPage(Math.min(page + 30, 60));
                  } else {
                    setPage(page + 30);
                  }
                }}>
                See More
              </button>
            </div>
          )}
          <br />
          <br />
        </div>
        {!mobile && (
          <div className="grid-4">
            <div className="inbox">
              <ReferralBox />
            </div>
          </div>
        )}
      </div>
      <CreatePost active={create} setActive={setCreate} />
    </div>
  );
};

const indexToPost = (index, length) => {
  switch (index) {
    case 0:
      // IF NOT MORE THAN THREE POSTS, BE SURE TO SHOW AT LEAST ONE
      if (length < 3) {
        return 0;
      }
      return -1;
    case 3:
      return 0;
    case 7:
      return 1;
    case 15:
      return 2;
    case 21:
      return 3;
    case 30:
      return 4;
    case 37:
      return 5;
    case 45:
      return 6;
    case 53:
      return 7;
    default:
      return -1;
  }
};

export default Home;
