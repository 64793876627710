import React, {useEffect, useState} from 'react';
import {FaBolt, FaCreativeCommonsShare, FaLink} from 'react-icons/fa6';
import {IoLocationOutline} from 'react-icons/io5';
import {LuCalendarDays} from 'react-icons/lu';
import {useLocation, useNavigate} from 'react-router-dom';
import {track} from '../../api/analytics';
import {formatEventDate} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {SecondaryButton} from '../../components/buttons/buttons';
import constants from '../../components/constants';
import {FileViewer} from '../../components/inputs/uploader';
import {InteractiveOpportunityItem} from '../../components/item-details/opportunity-item';
import {
  SendNoteModal,
  SharePostModal,
} from '../../components/modal/external-engagement-modals';
import {SimpleTagDisplay} from '../../components/text/display-fields';
import {GroupsIcon} from '../../components/widgets/widgets';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import useTracker from '../../hooks/use-tracker';
import useUtilities from '../../hooks/use-utilities';
import {useWorkspace} from '../../hooks/use-workspace';

export const ContentDetails = ({item}) => {
  const {copyString} = useUtilities();
  const {pathname} = useLocation();
  const {trackPage} = useTracker();
  const {parseTextForLinks} = useStringFormatter();
  const {
    state: {id},
  } = useAuth();

  const [analytics, setAnalytics] = useState({
    views: 0,
    share_count: 0,
    refer_count: 0,
    opportunity_count: 0,
  });
  const [show, setShow] = useState(false);
  const [popup, setPopup] = useState({on: false, message: ''});
  const [add, setAdd] = useState(false);

  const {
    title,
    description,
    type,
    location,
    inner_tags,
    outer_tags,
    media,
    links,
    user_id,
    group_ids,
  } = item;

  const owner = id === user_id;
  const pretty_date = formatEventDate(item);

  useEffect(() => {
    const track = async () => {
      const data = await trackPage(pathname);
      setAnalytics(data);
    };
    track();
  }, []);

  return (
    <div>
      <div className="card-minimum margin-h16">
        {media &&
          media.map(file => {
            const {key, size, type, url} = file;
            return (
              <div key={key} className="card-border">
                <FileViewer type={type} url={url} />
              </div>
            );
          })}
        <div className="margin-32">
          <GroupsIcon group_ids={group_ids} />
          <h4>{title}</h4>
          <p
            className={`text-left text-formatting-preserved ${
              type === 'event' && 'text-secondary'
            } ${type !== 'event' && 'text-1dot25em'} `}>
            {parseTextForLinks(description)}
          </p>
          <SimpleTagDisplay inner_tags={inner_tags} outer_tags={outer_tags} />
          {links &&
            links.map(link => {
              const {url, text} = link;
              return (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={url}>
                  {text}
                </a>
              );
            })}
          <div className="border-bottom margin-v4" />
          {pretty_date && (
            <div className="flex-row align-center">
              <LuCalendarDays size={'20px'} />
              <p className="margin-h8 text-14 text-left">{pretty_date}</p>
            </div>
          )}
          {location && (
            <div className="flex-row align-center">
              <IoLocationOutline size={'20px'} />
              <p className="margin-h8 text-14 text-left">{location}</p>
            </div>
          )}
          <Analytics analytics={analytics} />
          <div className="flex-row">
            <button
              className="button-container"
              onClick={() => {
                setShow(!show);
              }}>
              {owner ? 'Send Note' : 'Reach Out'}
            </button>
            <button className="invite-external-button" onClick={setAdd}>
              Share
            </button>
            <SecondaryButton
              onClick={() => {
                const url = window.location.href;
                copyString(url);
                setPopup({on: true, message: 'URL copied to clipboard.'});
                track('url_copied', {url});
              }}>
              <FaLink size={'20px'} />
            </SecondaryButton>
          </div>
        </div>
      </div>
      <AskDetails item={item} />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <SharePostModal active={add} setActive={setAdd} post={item} />
      <SendNoteModal active={show} setActive={setShow} post={item} />
    </div>
  );
};

const Analytics = ({analytics}) => {
  const {views, share_count, refer_count, opportunity_count} = analytics;

  return (
    <div className="flex-row-wrap">
      {!!views && <AnalyticsItem text="Views" count={views} />}
      {!!share_count && <AnalyticsItem text="Shares" count={share_count} />}
      {!!refer_count && <AnalyticsItem text="Notes" count={refer_count} />}
      {!!opportunity_count && (
        <AnalyticsItem text="Matches" count={opportunity_count} />
      )}
    </div>
  );
};

const AnalyticsItem = ({text, count}) => {
  return (
    <div className="margin-h8">
      <p className="text-12">{count}</p>
      <p className="text-12 text-secondary">{text}</p>
    </div>
  );
};

export const PeopleDetails = ({item}) => {
  const navigate = useNavigate();
  const {
    state: {auth, profiles, organizations},
  } = useAuth();

  const {user_id, org_id} = item || {};

  const person = profiles[user_id] || {};

  return (
    <div className="card-minimum margin-h16 margin-v32 padding-16">
      <h4>📲People</h4>
      <div className="padding-top8">
        <Person
          item={person}
          onClick={() => {
            if (auth) {
              navigate(`/feed/profiles/detail/${user_id}`);
            }
          }}
        />
      </div>
    </div>
  );
};

export const PostOpportunities = ({post_id}) => {
  const {
    state: {content_opps},
  } = useWorkspace();

  const [nothingToShow, setNothingToShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (opps || opps?.length === 0) {
        setNothingToShow(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const opps = content_opps?.[post_id] ?? [];
  return (
    <div className="padding-16">
      <div className="flex-column align-center padding-bottom16 margin-h16">
        <span className="create-opportunity-item">
          <FaBolt />
        </span>
        <h2 className="invite-modal-title">Recommended Opportunities</h2>
        <p className="invite-modal-description">
          {opps?.length === 0
            ? `${
                !nothingToShow
                  ? 'Loading potential partners...'
                  : 'No opportunities at this time'
              }`
            : `These PublicMind members could be ideal partners for your post. Click on any below to review or share your post via email`}
        </p>
      </div>
      <div>
        {opps.map(opp_id => {
          return <InteractiveOpportunityItem opp_id={opp_id} />;
        })}
      </div>
    </div>
  );
};

const Person = ({item, onClick}) => {
  const {first_name, last_name, position, company, email} = item;
  return (
    <div className="card-border padding-16 clickable" onClick={onClick}>
      <p className="text-left text-20 text-bold">
        {first_name} {last_name}
      </p>
      <p className="text-left text-secondary text-14">{position}</p>
      {/* <a
        href={`mailto:${email}`}
        onClick={() => {
          track('email_clicked', {email});
        }}>
        {email}
      </a> */}
    </div>
  );
};

export const AskDetails = ({item}) => {
  const {
    state: {profiles},
  } = useAuth();
  const {asks, user_id} = item;

  const profile = profiles?.[user_id] ?? {};
  const {first_name, last_name} = profile;

  if (!asks || !asks.length) {
    return null;
  }

  return (
    <div className="card margin-h16 margin-v32 padding-16 ">
      <p className="text-left text-bold">
        🌟{first_name} {last_name} is looking for...
      </p>
      {asks.map((ask, i) => {
        const {title, description, emoji} = ask;
        return (
          <div key={i} className="card-border">
            <p className="text-left text-bold">
              {emoji} {title}
            </p>
            <p className="text-left text-secondary text-formatting-preserved">
              {description}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export const SignupCard = () => {
  const navigate = useNavigate();

  return (
    <div className="card-minimal justify-content align-center margin-h16">
      <p>
        🚀<strong>Join the PublicMind waitlist</strong> to create your own{' '}
        <strong>exclusive opportunties</strong> like this!
      </p>
      <button
        onClick={() => {
          navigate('/signup');
        }}
        style={{
          width: '12vw',
          height: '5vh',
        }}>
        🚀 Join 🚀
      </button>
    </div>
  );
};

export const OtherOpportunities = ({item}) => {
  const {
    state: {posts, post_ids},
  } = useWorkspace();

  const {id} = item;

  return (
    <div className="card-minimum margin-h16 padding-16">
      <h4>🔎Other Recent Projects</h4>
      <div className="grid-container">
        {post_ids
          .filter(i => i !== id)
          .slice(0, 2)
          .map(id => {
            return (
              <div key={id} className="grid-6">
                <Preview item={posts[id]} shareable={true} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const Preview = ({item, shareable}) => {
  const navigate = useNavigate();
  const {copyString} = useUtilities();
  const {id, title, description, media, inner_tags, outer_tags, type} = item;

  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <div className="card-border">
      {media &&
        media.map(file => {
          const {type, key, url} = file;
          return <FileViewer type={type} key={key} url={url} />;
        })}
      <h4>{title}</h4>

      <p
        className={`text-left text-formatting-preserved ${
          type === 'event' && 'text-secondary'
        }`}>
        {description}
      </p>
      <SimpleTagDisplay inner_tags={inner_tags} outer_tags={outer_tags} />
      {shareable && (
        <div className="flex-row justify-center align-center">
          <button
            onClick={() => {
              navigate(`/feed/posts/detail/${id}`);
            }}>
            VISIT
          </button>
          <FaCreativeCommonsShare
            className="button-icon"
            onClick={() => {
              const url = constants.root_url + `/feed/posts/detail/${id}`;
              copyString(url);
              setPopup({on: true, message: 'URL copied to clipboard.'});
              track('url_copied', {url});
            }}
            size={'30px'}
          />
        </div>
      )}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};
